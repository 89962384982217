import React, { useEffect } from 'react'
import {
  Body2,
  H5,
  Message,
  SmallButton,
  Spacer,
  SpecialContent,
  Text,
} from '../components'
import { isIOS } from '../helper/userAgents'
import { loadProperty, saveProperty } from '../helper/LocalStorage'

type Props = {}

// let appIsInstalled = false

// let deferredPrompt

// window.addEventListener('beforeinstallprompt', (event) => {
//   event.preventDefault()
//   deferredPrompt = event
//   // Show your custom install button or take any other action
// })

let appIsInstalled = loadProperty('appInstalled') || false

if (
  window.matchMedia('(display-mode: standalone)').matches ||
  (window.navigator as any).standalone
) {
  // PWA is installed
  console.log('PWA is already installed')
  appIsInstalled = true
} else {
  // PWA is not installed
  console.log('PWA is not installed')
}

export const InstallPrompt: React.FC<Props> = () => {
  const [installDone, setInstallDone] = React.useState(false)
  const [browserCheckDone, setBrowserCheckDone] = React.useState(false)
  const [deferredPrompt, setDeferredPrompt] = React.useState<any>(undefined)

  useEffect(() => {
    const listener = (event) => {
      event.preventDefault()
      // const deferredPrompt = event
      setDeferredPrompt(event)

      console.log('beforeinstallprompt event fired', event)
      // Show your custom install button or take any other action
    }

    window.addEventListener('beforeinstallprompt', listener)

    setBrowserCheckDone(true)
    return () => {
      window.removeEventListener('beforeinstallprompt', listener)
    }
  }, [])

  if (appIsInstalled || !browserCheckDone) {
    return null
  }

  console.log('render', deferredPrompt)

  if (!deferredPrompt) {
    return (
      <div>
        <Spacer size={2} />
        <H5>
          <Message id="INSTALL_MANUALLY_HEADLINE" />
        </H5>
        <Spacer size={1} />
        {isIOS ? (
          <Text>
            <Message id="INSTALL_MANUALLY_MESSAGE_IOS" renderRawHtml />
          </Text>
        ) : (
          <Text>
            <Message id="INSTALL_MANUALLY_MESSAGE" renderRawHtml />
          </Text>
        )}
      </div>
    )
  }

  return (
    <div>
      <Spacer size={2} />
      <SpecialContent padding="1rem 0.3rem">
        <H5>
          <Message id="INSTALL_PROMT_HEADLINE" />
        </H5>
        <Spacer size={1} />
        <Body2>
          <Message id="INSTALL_PROMT_MESSAGE" />
        </Body2>
        <Spacer size={1} />
        {/* <Body2>
        <Message id="INSTALL_MANUALLY_MESSAGE" renderRawHtml />
      </Body2> */}
        <SmallButton
          disabled={installDone}
          onClick={() => {
            if (deferredPrompt) {
              deferredPrompt.prompt()
              deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                  console.log('App installed successfully')
                  setInstallDone(true)
                  saveProperty('appInstalled', true)
                }
                // deferredPrompt = null
              })
            }
          }}
        >
          Install App
        </SmallButton>

        {installDone && <Text>App installed successfully</Text>}
      </SpecialContent>
    </div>
  )
}

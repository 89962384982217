export const KEY = 'fusionKey'
export const VERSION = '1.1.0'

export const WITH_ARTIST_INFO = true

const REACT_APP_SHOW_CONFIG = process.env.REACT_APP_SHOW_CONFIG === 'true'

const REACT_APP_SHOW_NOT_REALEASED =
  process.env.REACT_APP_SHOW_NOT_REALEASED === 'true'
const REACT_APP_SHOW_DEPARTURE = process.env.REACT_APP_SHOW_DEPARTURE === 'true'
const REACT_APP_SHOW_MAP = process.env.REACT_APP_SHOW_MAP === 'true'
const REACT_APP_SHOW_MAP_FINDER =
  process.env.REACT_APP_SHOW_MAP_FINDER === 'true'
const REACT_APP_SHOW_ARTISTS = process.env.REACT_APP_SHOW_ARTISTS === 'true'

const REACT_APP_INFO_URL = process.env.REACT_APP_INFO_URL || '/info.json'
const REACT_APP_URL =
  process.env.REACT_APP_URL || 'https://timetable.fusion-festival.de'

// default 5min
const defaultUpdateTime = 5 * 60 * 1000

const REACT_APP_INFO_UPDATE_TIME = parseInt(
  process.env.REACT_APP_INFO_UPDATE_TIME || defaultUpdateTime.toString()
)

export const Config = {
  REACT_APP_SHOW_NOT_REALEASED,
  REACT_APP_SHOW_DEPARTURE,
  REACT_APP_SHOW_MAP,
  REACT_APP_SHOW_MAP_FINDER,
  REACT_APP_SHOW_ARTISTS,
  REACT_APP_INFO_URL,
  REACT_APP_INFO_UPDATE_TIME,
  REACT_APP_URL,
  WITH_ARTIST_INFO,
  KEY,
  VERSION,
}

if (REACT_APP_SHOW_CONFIG) {
  console.log('Config', Config)
}

import React from 'react'
import { SmallButton } from '../Button'
import Message from '../Message'
import classes from './Modal.module.css'

interface ModalProps {
  title: string | React.JSX.Element
  text: string | React.JSX.Element
  isOpen: boolean
  onClose: () => void
}

export const FsnModal: React.FC<ModalProps> = ({
  title,
  text,
  isOpen,
  onClose,
}) => {
  if (!isOpen) return null

  return (
    <div className={classes.FsnModal}>
      <div className={classes.content}>
        <h2 className={classes.title}>{title}</h2>
        <p className={classes.text}>{text}</p>
        <SmallButton className={classes.closeBtn} onClick={onClose}>
          <Message id="BUTTON_UNDERSTOOD" />
        </SmallButton>
      </div>
    </div>
  )
}

import React, { FC, Fragment } from 'react'
import styled from 'styled-components/macro'
import {
  Page,
  Content,
  BottomDocked,
  Row,
  Button,
  // H1,
  H2,
  // H3,
  H4,
  Text,
  Spacer,
  T,
  BreakLongWords,
  Col,
  colors,
  BubbleItem,
} from '../components'
import { ChangeButton } from '../Filter/ChangeButton'
import { getDayNameLong, getTimeString } from '../helper/DateFornat'
import { useLang } from '../state/language'
import { PlusButton } from '../TimetableList/PlusButton'

interface ArtistInfoData {
  id: number
  genre: string
  artist: string
  name?: string
  descDe: string
  descEn: string
  website: string
  soundsample: string
  shows?: Show[]
}

interface Show {
  start: string
  end: string
  stage: string
  floor: string
  isFavorite?: boolean
  showId: string
}

const Buttons = styled.div`
  > * {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
`

const SShow = styled.div`
  margin-bottom: 1.5rem;
`

const PlayTimes = styled.div`
  background: ${colors.backgroundBlack};
  border-radius: 1rem;
  padding: 1.5rem;
`

const Divider = styled.div`
  border-bottom: 1px solid ${colors.primary};
`

type Props = ArtistInfoData & {
  onClose: () => void
  toggleFav?: (id: string) => void
  info: ArtistInfoData
  artist: ArtistInfoData
}

const ArtistInfo: FC<Props> = ({ info, artist, onClose, toggleFav }) => {
  // const { artist, descDe, descEn, website, soundsample, shows } = info
  const {
    artist: artistName,
    descDe,
    descEn,
    website,
    soundsample,
    shows,
  } = artist

  let lang = useLang()
  let text = lang === 'de' ? descDe : descEn

  // use other language text of own lang is not there
  if (!text) {
    text = lang === 'de' ? descEn : descDe
  }

  return (
    <Page withBackground={true} alignLeft center>
      <Content>
        <H2>
          <BreakLongWords>{artistName}</BreakLongWords>
        </H2>
        {artist.name && (
          <>
            <Spacer size={1.5} />
            <H4>
              <BreakLongWords>{artist.name}</BreakLongWords>
            </H4>
          </>
        )}
        <Spacer size={3} />
        <Text>{text}</Text>

        {(!!website || !!soundsample) && (
          <>
            <Spacer size={2} />
            <Buttons>
              {!!website && (
                <ChangeButton onClick={() => window.open(website, '_blank')}>
                  Homepage
                </ChangeButton>
              )}

              {!!soundsample && (
                <ChangeButton
                  onClick={() => window.open(soundsample, '_blank')}
                >
                  Sound sample
                </ChangeButton>
              )}
            </Buttons>
          </>
        )}
        <Spacer size={4} />
        {!!shows && shows.length > 1 && (
          <PlayTimes>
            <H4>
              <T id="ARTIST_INFO_PLAYTIME" />
            </H4>
            <Spacer size={1} />
            <Divider />
            <Spacer size={1} />
            {shows.map((show) => {
              const start = new Date(show.start)
              const end = new Date(show.end)
              return (
                <Fragment key={show.showId}>
                  <SShow>
                    <Row>
                      <Col flex={1}>
                        <div>
                          <div>
                            <BubbleItem>{getDayNameLong(start)}</BubbleItem>
                          </div>
                          <div>
                            <BubbleItem>
                              {getTimeString(start)} - {getTimeString(end)}
                            </BubbleItem>
                          </div>
                          <div>
                            <BubbleItem>{show.floor}</BubbleItem>
                          </div>
                        </div>
                      </Col>

                      <PlusButton
                        isFavorite={show.isFavorite}
                        onClick={() => {
                          toggleFav && toggleFav(show.showId)
                        }}
                      />
                    </Row>
                  </SShow>
                  <Spacer size={1} />
                  <Divider />
                  <Spacer size={1} />
                </Fragment>
              )
            })}
          </PlayTimes>
        )}
      </Content>
      <BottomDocked>
        <Row>
          <Button onClick={onClose}>
            <T id="INFO_CLOSE_BTN" />
          </Button>
        </Row>
      </BottomDocked>
    </Page>
  )
}

export default ArtistInfo

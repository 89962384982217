import * as R from 'ramda'
import { createAction, handleActions } from 'redux-actions'
import { makeNamespace } from '../utils'
import { createSelector } from 'reselect'
import { days, getDayString, getTimeString } from '../../helper/DateFornat'
import { floors } from '../filter/consts'
// import validatePassword from '../../helper/validatePassword'

const reducerName = 'timetable'

const ns = makeNamespace(reducerName)
// types
const TOGGLE_FAV = ns.getType('TOGGLE_FAV')
const IMPORT_FAVS = ns.getType('IMPORT_FAVS')
const SET_PASSWORD = ns.getType('SET_PASSWORD')
const SET_LOADING = ns.getType('SET_LOADING')

// actions
export const toggleFav = createAction(TOGGLE_FAV)
export const importFavs = createAction(IMPORT_FAVS)
export const setPassword = createAction(SET_PASSWORD)
export const setLoading = createAction(SET_LOADING)

interface TimetableEntry {
  id: number
  genre: string
  artist: string
  name?: string
  start: string
  end: string
  floor: string
  showId: string
  hasInfo: boolean

  day: string
  date: string
  from: string
  to: string
}

interface Artist {
  id: number
  genre: string
  hasInfo: boolean
  artist: string
  name?: string
  shows?: Show[]
  soundsample?: string
  website?: string
}

interface Show {
  start: string
  end: string
  floor: string
  showId: string
}

// IMPORTANT: this is stored here locally and NOT IN STATE but is used by the selectors
let timetable: TimetableEntry[] = []
let artists: Artist[] = []

let isTTLoading = false

export const loadTimeTable = () => {
  // eslint-disable-next-line no-console
  console.log('loadTimeTable()')
  return (dispatch, getState) => {
    if (timetable.length > 0 || isTTLoading) {
      // console.log('timetable already loaded.')
      return
    }

    isTTLoading = true

    dispatch(setLoading(true))

    // import('../../timetable.json')
    //   .then((m) => m.default)
    //   .then((loadedTimetable) => {
    //     // eslint-disable-next-line no-console
    //     console.log('Timetable loaded')

    //     timetable = decodeTimetable(loadedTimetable)

    import('../../artists.json')
      .then((m) => m.default)
      .then((loadedArtists) => {
        // eslint-disable-next-line no-console
        console.log('Artists loaded')
        // @ts-ignore
        artists = loadedArtists
        timetable = decodeTimetable(artists)
        dispatch(setLoading(false))
      })
    // })
  }
}

const getOriginalState = (): {
  favorites: string[]
  password: string | null
  isLoading: boolean
} => ({
  favorites: [],
  password: null,
  isLoading: true,
})

function decodeTimetable(artists: Artist[]): TimetableEntry[] {
  const flatArtist = (entry: Artist) => {
    const newEntry = {
      ...entry,
    }

    let shows = R.map((show) => {
      const newShow = {
        ...show,
        ...entry,
      }
      delete newShow.shows
      return newShow
    })(entry.shows || [])

    newEntry.shows = shows

    return newEntry
  }

  let timetable = R.pipe(
    R.map(flatArtist),
    R.map(R.prop('shows')),
    R.flatten,
    R.map((entry) => {
      const start = new Date(entry.start)
      const end = new Date(entry.end)

      let to = getTimeString(end)

      const hours = (end.getTime() - start.getTime()) / 1000 / 60 / 60
      if (hours > 12) {
        to = days[end.getDay()] + ' ' + getTimeString(end)
      }

      return {
        ...entry,
        day: days[start.getDay()],
        date: getDayString(start),
        from: getTimeString(start),
        to,
      }
    }),
    R.sortWith([
      R.ascend((show) => {
        return new Date(show.start).getTime()
      }),
      R.ascend((show) => {
        return floors.indexOf(show.floor)
      }),
    ])
  )(artists)

  // console.log(timetable)
  return timetable
}

// loadTimeTable()

export default handleActions(
  // @ts-ignore
  {
    [TOGGLE_FAV]: (state, action: any) => {
      const itemId: string = action.payload
      let favorites = [...state.favorites]

      const index = R.findIndex((val) => val === itemId)(favorites)

      if (index === -1) {
        favorites.push(itemId)
      } else {
        favorites = R.remove(index, 1, favorites)
      }

      return {
        ...state,
        favorites,
      }
    },
    [IMPORT_FAVS]: (state, action) => {
      let favorites = action.payload

      // console.log('old favs: ', state.favorites)

      favorites = R.pipe(R.concat(favorites), R.uniq)(state.favorites)

      // console.log('new favs: ', favorites)

      return {
        ...state,
        favorites,
      }
    },
    // [SET_PASSWORD]: (state, action) => {
    //   let password = action.payload
    //   password = password.toLowerCase()
    //   return {
    //     ...state,
    //     password,
    //   }
    // },
    [SET_LOADING]: (state, action) => {
      return {
        ...state,
        isLoading: action.payload,
      }
    },
  },
  getOriginalState()
)

// selectors

const getOwnState = ns.getState
export const getFavorites = (state) => getOwnState(state).favorites
export const getPassword = (state) => getOwnState(state).password
export const isLoading = (state) => getOwnState(state).isLoading

export const getTimetable = (state) => timetable
export const getArists = (state) => artists
export const getArtistById = (id) => {
  // console.log('id: ', artists)
  return R.find(R.propEq('id', parseInt(id, 10)))(artists)
}

export const getEntries = createSelector(
  getFavorites,
  (state, onlyFavorites) => onlyFavorites,
  (state, onlyFavorites) => timetable,
  (favorites, onlyFavorites, timetable) => {
    // let entries = timetable.slice(0, 100)
    let entries = timetable

    // console.log('onlyFavorites: ', onlyFavorites)

    if (onlyFavorites) {
      entries = R.filter((entry) => R.contains(entry.showId, favorites))(
        entries
      )
    }

    entries = R.map((entry) => {
      if (R.contains(entry.showId, favorites)) {
        return {
          ...entry,
          isFavorite: true,
        }
      }
      return entry
    })(entries)

    // console.log('getEntries() ', entries)

    return entries
  }
)

// @ts-ignore
export const getArtistWithShows = createSelector(
  getFavorites,
  (state, id) => getArtistById(id),
  (favorites: string[], artist: Artist) => {
    // console.log({ favorites, artist })

    if (!artist) {
      return
    }

    const shows = R.map((show) => {
      if (R.contains(show.showId, favorites)) {
        return {
          ...show,
          isFavorite: true,
        }
      }
      return show
    })(artist.shows || [])

    const artistWithShows = {
      ...artist,
      shows,
    }

    // console.log('artistWithShows() ', artistWithShows)

    return artistWithShows
  }
)

import { NearbyType } from '../models/map'

export const nearbyPlaces: NearbyType[] = [
  'Exit',
  'Water',
  'Wc',
  'Shower',
  'Spital',
  'Feuerwehr',
]

export const facilities = [
  'ATM',
  'Eclipse',
  'Botschaft 1',
  'Botschaft 2',
  'Tower',
]

import styled from 'styled-components'
import { colors } from '../../../components'

const RoundButton = styled.div<{
  isActive: boolean
}>`
  color: ${colors.white};
  cursor: pointer;
  background-color: ${(props) =>
    props.isActive ? colors.secondary : colors.backgroundBlack};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  z-index: 1;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);

  img {
    transform-origin: 'center center';
  }
`

export function MapButton(props: {
  iconSrc: string
  isActive: boolean
  onClick: () => void
  alt?: string
  bearing?: number
}) {
  const { iconSrc, alt, onClick, isActive, bearing } = props

  let theBearing = bearing

  if (bearing && bearing < 0) {
    theBearing = 360 + bearing
  }

  return (
    <RoundButton onClick={onClick} isActive={isActive}>
      <img
        style={{
          transform: `rotate(${theBearing && Math.abs(360 - theBearing)}deg)`,
        }}
        alt={alt ?? ''}
        src={iconSrc}
      />
    </RoundButton>
  )
}

import { connect } from 'react-redux'
import FilterSelects from './FilterSelects'
import { getFilters } from '../state/filter/selectors'
import {
  changeFilter,
  closeFilter,
  resetFilter,
  openFilter,
} from '../state/filter/actions'

export default connect(
  (state, props) => {
    return {
      filters: getFilters(state),
    }
  },
  {
    openFilter,
    changeFilter,
    closeFilter,
    resetFilter,
  }
)(FilterSelects)

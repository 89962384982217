import styled from 'styled-components/macro'
import colors from '../components/colors'
import { ReactComponent as ChangeIcon } from '../images/icons_2023/change-filter.svg'

const ChangeButton = styled.button`
  flex: 0 1 auto;
  padding: 5px 1rem 5px;
  font-size: 14px;
  /* text-transform: uppercase; */
  background: ${colors.secondary};
  border: none;
  color: ${colors.white};
  min-width: 6rem;
  border-radius: 10px;
`

export { ChangeIcon, ChangeButton }

import React from 'react'
import styled from 'styled-components/macro'
import { contains } from 'ramda'
import { Message, Button, BottomDocked, colors, Row } from '../components'
import img from '../images/bg-pattern-23.png'
import { floorDescriptions } from '../state/filter/consts'
import { InfoButton, SPlusIcon } from '../TimetableList/PlusButton'

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 1);

  display: flex;
  flex-direction: column;
  z-index: 1000;

  background-image: url(${img});
  background-repeat: repeat;
  /* background-size: 20%; */
`

const Options = styled.div`
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  flex: 1;
  padding: 2rem;
  padding-bottom: 10rem;
`

const Option = styled.div`
  position: relative;
  font-size: 20px;
  font-family: 'Inter-bold';
  padding: 0.7rem;
  margin: 2px 0;
  color: ${(props) =>
    props.isActive ? colors.textInverted : colors.textInverted};
  background-color: ${(props) => (props.isActive ? colors.primary : 'none')};
  text-align: center;
  text-transform: uppercase;
  border-top: 1px solid ${colors.primary};
  :last-child {
    border-bottom: 1px solid ${colors.primary};
  }
  user-select: none;
`

const OkButton = styled(Button)`
  flex: 0 1 auto;
  :last-child {
    margin-top: 1rem;
  }
  font-family: 'Inter-bold';
`

const InfoButtonContainer = styled.div`
  position: absolute;
  right: 0;
  top: 4px;
`

const getFilterString = (filterId, value) => {
  // console.log('getFilterString', filterId, value)
  if (filterId === 'days') {
    return <Message id="DAY" data={value} />
  }
  if (filterId === 'types') {
    return <Message id="TYPE" data={value} />
  }

  return value
}

const hasFloorInfo = (filterId, value) => {
  const hasInfo = filterId === 'floors' && floorDescriptions.de[value]
  // console.log('hasFloorInfo', filterId, value, hasInfo)
  return hasInfo
}

const FilterSelect = ({
  options,
  multiple,
  onClose,
  onChange,
  onReset,
  openFilter,
  changeFilter,
  value,
  filterId,
  show,
}) => {
  return (
    <Overlay show={show}>
      {/* <OverlayInner> */}
      <Options>
        {options.map((option, key) => {
          const hasInfo = hasFloorInfo(filterId, option)
          // const hasInfo = true
          const isActive = value === option || contains(option, value)
          return (
            <Option
              key={key}
              isActive={isActive}
              onClick={() => onChange(option)}
            >
              {getFilterString(filterId, option)}

              {hasInfo && (
                <InfoButtonContainer>
                  <InfoButton
                    onClick={(event) => {
                      event.preventDefault()
                      event.stopPropagation()
                      // console.log('info', option)
                      changeFilter({ filterId: 'floorInfo', value: option })
                      openFilter('floorInfo')
                    }}
                  >
                    <SPlusIcon>i</SPlusIcon>
                  </InfoButton>
                </InfoButtonContainer>
              )}
            </Option>
          )
        })}
      </Options>
      <BottomDocked>
        <Row flexDirection="column" alignItems="initial">
          <OkButton onClick={onClose}>OK</OkButton>
          <OkButton onClick={onReset}>Reset</OkButton>
        </Row>
      </BottomDocked>
    </Overlay>
  )
}

export default FilterSelect

import React, { FC, useEffect, useMemo, useState } from 'react'
import Entry, { Act } from './Entry'
import { H3, Text, TextContent, Spacer } from '../components/Typo'
import Message from '../components/Message'
import TimeHeader from './TimeHeader'
import styled from 'styled-components/macro'
import { device } from '../device'

const Table = styled.table`
  width: 100%;
  /* margin-bottom: -4px; */
  border-spacing: 0;
`

const searchFieldHeight = 47

const StickyHeader = styled.th<{ withSearch: boolean; withDays: boolean }>`
  position: sticky;
  top: ${(props) => {
    let top = 0
    if (props.withSearch) {
      top += searchFieldHeight
    }
    if (props.withDays) {
      top += 68
    }
    return top
  }}px;

  @media ${device.mobileM} {
    top: ${(props) => {
      let top = 0
      if (props.withSearch) {
        top += searchFieldHeight
      }
      if (props.withDays) {
        top += 78
      }
      return top
    }}px;
  }

  z-index: 10;
`

const IsLoadingMessage = ({ isFavoritesList }) => (
  <TextContent white>
    <Spacer size={4} />
    <Text>
      <Message id="LOADING" />
    </Text>
  </TextContent>
)

const NoEntriesMessage = ({
  isFavoritesList,
  isOrderByName,
}: {
  isFavoritesList: boolean
  isOrderByName: boolean
}) => (
  <TextContent white>
    <Spacer size={4} />
    <H3>
      <Message
        id={
          isFavoritesList
            ? 'FAVORITES_NO_ENTRIES_HEADLNE'
            : 'TIMETABLE_NO_ENTRIES_HEADLNE'
        }
      />
    </H3>
    <Spacer size={1} />
    <Text>
      <Message
        id={
          !isFavoritesList
            ? 'TIMETABLE_NO_ENTRIES_MESSAGE'
            : isOrderByName
            ? 'FAVORITES_NO_ENTRIES_MESSAGE'
            : 'FAVORITES_NO_ENTRIES_MESSAGE_DAY'
        }
      />
    </Text>
  </TextContent>
)

type Props = {
  type: 'favorites' | 'artists' | 'timetable'
  orderBy: 'time' | 'name'
  entries: Act[][]
  isLoading: boolean
  toggleFav: (showId: string) => void
  openFilter: (filterId: string) => void
  changeFilter: (params: { filterId: string; value: any }) => void
  isSearch: boolean
  scrollToTime?: string
  day: string
}

const Entries: FC<Props> = (props) => {
  const {
    entries,
    isLoading,
    openFilter,
    changeFilter,
    toggleFav,
    isSearch,
    scrollToTime,
    day,
  } = props

  const isFavoritesList = props.type === 'favorites'
  const isArtistsList = props.type === 'artists'
  const isOrderByName = props.orderBy === 'name'

  const [firstRender, setFirstRender] = useState(true)

  useEffect(() => {
    setFirstRender(false)

    if (scrollToTime && !isSearch) {
      const element = document.querySelector(`[data-time='${scrollToTime}']`)
      if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'auto' })
      }
    }
  }, [day, isSearch, scrollToTime])

  const entriesToRender = useMemo(() => {
    return firstRender ? entries.slice(0, 1) : entries
  }, [entries, firstRender])

  if (isLoading) {
    return <IsLoadingMessage isFavoritesList={isFavoritesList} />
  }

  if (entries.length === 0) {
    return (
      <NoEntriesMessage
        isFavoritesList={isFavoritesList}
        isOrderByName={isOrderByName}
      />
    )
  }

  return (
    <>
      {entriesToRender.map((entriesOfHour, key) => {
        const firstAct = entriesOfHour[0]
        return (
          <Table
            key={key}
            data-time={
              isArtistsList || isOrderByName
                ? firstAct.artist.substring(0, 1).toUpperCase()
                : firstAct.from
            }
          >
            <thead>
              <tr>
                <StickyHeader
                  withSearch={!isFavoritesList}
                  withDays={!isOrderByName && !isSearch}
                >
                  <TimeHeader
                    from={
                      isArtistsList || isOrderByName
                        ? firstAct.artist.substring(0, 1).toUpperCase()
                        : firstAct.from
                    }
                    day={firstAct.day}
                    withExtras={isSearch && !isOrderByName}
                  />
                </StickyHeader>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {entriesOfHour.map((act, index) => {
                    return (
                      <Entry
                        act={act}
                        key={index}
                        onClick={toggleFav}
                        showTimeAndFloor={!isOrderByName}
                        onInfoClick={() => {
                          changeFilter({ filterId: 'info', value: act.showId })
                          openFilter('info')
                          // changeFilter({ filterId: 'floorInfo', value: 'Palapa' })
                          // openFilter('floorInfo')
                        }}
                      />
                    )
                  })}
                </td>
              </tr>
            </tbody>
          </Table>
        )
      })}
    </>
  )
}

export default Entries

import React from 'react'
import styled from 'styled-components/macro'
// import colors from '../components/colors'
// import FilterDisplay from './FilterDisplay'
// import { device } from '../device'
import DayButtons from './DayButtons'
import SearchField from './SearchField'
import { colors } from '../components'

// const SelectionContainer = styled.div`
//   padding: 1rem 1rem;
//   @media ${device.mobileM} {
//     padding: 1rem 2rem;
//   }
//   background-color: ${colors.filtersBackground};
// `

const StickyContainer = styled.div<{ isSticky?: boolean }>`
  position: ${(props) => (props.isSticky ? 'sticky' : 'relative')};
  top: -1px;
  z-index: ${colors.zIndex.stickyStuff};
`

const SearchContainer = styled.div`
  padding: 0;
`

const Selection = ({
  alwaysDayButtons,
  hideDayButtons,
  onlyDayButtons,
  filters,
  day,
  openFilter,
  changeFilter,
  scrollToTop,
  showFloors,
  isSticky,
}) => (
  <StickyContainer isSticky>
    {!onlyDayButtons && (
      <>
        <SearchContainer>
          <SearchField changeFilter={changeFilter} />
        </SearchContainer>
      </>
    )}

    {(!filters.search || alwaysDayButtons) && !hideDayButtons && (
      <DayButtons
        day={day}
        onDayClick={(day) => {
          changeFilter({ filterId: 'days', value: day })
          scrollToTop()
        }}
      />
    )}
  </StickyContainer>
)

export default Selection

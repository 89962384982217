import maplibregl, { Map } from 'maplibre-gl'
import 'maplibre-gl/dist/maplibre-gl.css'
import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Message } from '../components'
import exit from '../images/exit.svg'
import floors from '../images/floors.svg'
import locator from '../images/locator.svg'
import north from '../images/north.svg'
import tent from '../images/tent.svg'
import water from '../images/water.svg'

import wc from '../images/wc.svg'
import classes from './Map.module.css'
import { MapButton } from './components/map-button/MapButton'
import { PLACES } from './constants/coordinates'
import {
  FUSION_CENTER,
  MAX_NE,
  MAX_SW,
  NE,
  SW,
  TENT_ICON_KEY,
  TENT_INITIAL_POSITION,
} from './constants/map'
import { findNearestPlace, isPointInBounds } from './helpers/map'
import { EXITS, FLOORS, WATER, WC, addLayers } from './layers'
import {
  CAMPING_LAYERS,
  Coordinate,
  FsnLayerId,
  NearbyType,
} from './models/map'
import { createTent } from './tent'

const geolocator = new maplibregl.GeolocateControl({
  positionOptions: {
    enableHighAccuracy: true,
  },
  trackUserLocation: true,
})

const MAX_ZOOM = 16.5

export function MapComponent(props: {
  onUserFound: (userLoc: Coordinate) => void
}) {
  const { onUserFound } = props
  const mapContainer = useRef(null)

  let [userLocation, setUserLocation] = useState<{
    longitude: number
    latitude: number
  } | null>(null)

  let [userIsOutsideOfFsn, setUserIsOutsideOfFsn] = useState<boolean>(true)
  let [theMap, setTheMap] = useState<Map | null>(null)
  let [bearing, setBearing] = useState<number>(0)

  const location = useLocation()

  const [layersViz, setLayersViz] = useState(
    CAMPING_LAYERS.reduce((a, layer) => {
      a[layer] = 'visible'

      return a
    }, {})
  )

  useEffect(() => {
    const map = new maplibregl.Map({
      container: mapContainer.current!,
      style: { version: 8, sources: {}, layers: [] },
      center: FUSION_CENTER,
      maxBounds: [MAX_SW, MAX_NE],
      zoom: 13,
      minZoom: 12.8,
      maxZoom: MAX_ZOOM,
      pitchWithRotate: false,
      touchPitch: false,
    })
    map.addControl(geolocator)

    map.on('load', () => {
      setTheMap(map)
    })

    // let polygon: any = []

    // map.on('click', (e) => {
    //   polygon.push({ lat: e.lngLat.lat, lng: e.lngLat.lng })

    //   console.log(JSON.stringify(polygon))

    //   navigator.clipboard.writeText(JSON.stringify(polygon))
    // })

    map.on('rotate', () => {
      setBearing(map.getBearing())
    })

    geolocator.once('geolocate', () => {
      map.fitBounds([SW, NE])
    })

    geolocator.on('geolocate', (data) => {
      const userLocation = data.coords
      if (isPointInBounds(userLocation, { NE, SW })) {
        setUserIsOutsideOfFsn(false)
      }

      setUserLocation(userLocation)
      onUserFound(userLocation)
    })

    geolocator.on('outofmaxbounds', () => {
      setUserIsOutsideOfFsn(true)
    })

    return () => {
      if (map) {
        map.remove()
      }
    }
  }, [])

  useEffect(() => {
    if (!theMap) return

    theMap.fitBounds([SW, NE])

    addLayers(theMap)
    createTent(theMap)

    geolocator.trigger()
  }, [theMap])

  useEffect(() => {
    if (!theMap) return

    const queryParams = new URLSearchParams(location.search)

    const place = queryParams.get('place')
    const nearby = queryParams.get('nearby')

    if (!place && !nearby) {
      theMap.fitBounds([SW, NE])
      return
    }

    let theLat, theLng

    if (nearby && userLocation) {
      const nearestPlace = findNearestPlace(
        { lat: userLocation.latitude, lng: userLocation.longitude },
        nearby as NearbyType
      )

      theLat = nearestPlace?.lat
      theLng = nearestPlace?.lng
    } else if (place) {
      theLat = PLACES[place]?.lat
      theLng = PLACES[place]?.lng
    }

    if (theLat && theLng) {
      theMap.flyTo({
        center: [theLng, theLat],
        zoom: MAX_ZOOM,
        speed: 2,
        curve: 1.42,
      })
    }
  }, [location.search])

  const centerMe = () => {
    if (!userLocation) {
      geolocator.trigger()
      return
    }

    theMap!.flyTo({
      center: [userLocation.longitude, userLocation.latitude],
      zoom: MAX_ZOOM,
      speed: 2,
      curve: 1.42,
    })
  }

  const findMyTent = () => {
    const tentLocationState = localStorage.getItem(TENT_ICON_KEY)
    const tentLocation = tentLocationState
      ? JSON.parse(tentLocationState)
      : { lng: TENT_INITIAL_POSITION[0], lat: TENT_INITIAL_POSITION[1] }

    theMap!.flyTo({
      center: [tentLocation.lng, tentLocation.lat],
      zoom: MAX_ZOOM,
      speed: 2,
      curve: 1.42,
    })
  }
  const resetNorth = () => {
    if (!theMap) return

    theMap.resetNorth()
  }

  const toggleLayerViz = (layerId: FsnLayerId) => {
    if (!theMap) return

    const visibility = layersViz[layerId] === 'visible' ? 'none' : 'visible'

    theMap.setLayoutProperty(layerId, 'visibility', visibility)

    setLayersViz({
      ...layersViz,
      [layerId]: visibility,
    })
  }

  return (
    <>
      <div className={classes.buttons}>
        <MapButton
          onClick={() => toggleLayerViz(FLOORS)}
          isActive={layersViz[FLOORS] === 'visible'}
          iconSrc={floors}
        ></MapButton>

        <MapButton
          onClick={() => toggleLayerViz(WC)}
          isActive={layersViz[WC] === 'visible'}
          iconSrc={wc}
        ></MapButton>
        <MapButton
          onClick={() => toggleLayerViz(WATER)}
          isActive={layersViz[WATER] === 'visible'}
          iconSrc={water}
        ></MapButton>
        <MapButton
          onClick={() => toggleLayerViz(EXITS)}
          isActive={layersViz[EXITS] === 'visible'}
          iconSrc={exit}
        ></MapButton>
      </div>

      <div className={`${classes.buttons} ${classes.right}`}>
        <MapButton
          isActive={!!userLocation}
          onClick={centerMe}
          iconSrc={locator}
        ></MapButton>
        <MapButton
          onClick={resetNorth}
          isActive={bearing !== 0}
          bearing={bearing}
          iconSrc={north}
        ></MapButton>
        <MapButton
          isActive={true}
          onClick={findMyTent}
          iconSrc={tent}
        ></MapButton>
      </div>

      {userIsOutsideOfFsn && (
        <div className={classes.info}>
          <Message id="OUTSIDE_OF_FUSION" />
        </div>
      )}

      <div ref={mapContainer} className={classes.map} />
    </>
  )
}

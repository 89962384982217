import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { loadState } from '../helper/LocalStorage'
import saveStoreMiddleWare from './saveStoreMiddleware'

import createReducer from './reducers'

const logEnabled = process.env.NODE_ENV === 'development'

const logger = (store) => (next) => (action) => {
  // eslint-disable-next-line no-console
  logEnabled && console.log('dispatching', action)
  let result = next(action)
  // eslint-disable-next-line no-console
  logEnabled && console.log('next state', store.getState())
  return result
}

const appReducer = createReducer()

const DEBUG =
  process.env.NODE_ENV === 'development' ||
  window.location.pathname.includes('isDebug')

const middleware = [thunk, saveStoreMiddleWare, logger, DEBUG && logger].filter(
  Boolean
)

export default function configureStore(initialState) {
  initialState = loadState()
  const store = createStore(
    appReducer,
    initialState,
    applyMiddleware(...middleware)
  )

  return store
}

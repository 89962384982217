import React from 'react'
import { useT } from '../state/language'
import { LangProps } from '../state/language/de'

type Props = { id: LangProps; data?: any; renderRawHtml?: boolean }

const T = React.memo(({ id, data, renderRawHtml }: Props) => {
  const t = useT()

  const s = t(id, data)

  if (renderRawHtml) {
    return <span dangerouslySetInnerHTML={{ __html: s }} />
  }

  return <>{s}</>
})

export default T

import styled from 'styled-components/macro'

const BottomContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  padding-top: 0;
  padding-bottom: ${props => (props.noPaddingBottom ? 0 : '2rem')};
`

BottomContainer.Inner = styled.div`
  max-width: 500px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
`

export default BottomContainer

import styled from 'styled-components/macro'
import colors from './colors'

const TextContent = styled.div<{ white: boolean }>`
  color: ${(props) => (props.white ? colors.textInverted : colors.text)};
  text-align: center;
  padding: 0 1rem;
`

const H1 = styled.div<{ white?: boolean; halfMargin?: boolean }>`
  font-size: 49px;
  line-height: 1.2;
  font-family: 'Inter-bold';
  /* margin: ${(props) => (props.halfMargin ? '0.3rem 0 0' : '1rem 0')}; */
  color: ${(props) => (props.white ? colors.text : colors.textInverted)};
`

const H2 = styled(H1)`
  font-size: 39px;
`

const H3 = styled(H1)`
  font-size: 31px;
`

const H4 = styled(H1)`
  font-size: 25px;
`

const H5 = styled(H1)`
  font-size: 20px;
`

// Body1 Body2

const Body1 = styled.div<{ white?: boolean; inline?: boolean; color?: string }>`
  font-size: 16px;
  line-height: 1.3;
  font-weight: 400;
  color: ${(props) =>
    props.color
      ? props.color
      : props.white
      ? colors.text
      : colors.textInverted};
  display: ${(props) => (props.inline ? 'inline' : 'block')};
`

const Body2 = styled(Body1)`
  font-size: 14px;
`

const Text = styled(Body1)<{
  small?: boolean
  white?: boolean
  inline?: boolean
}>`
  font-size: ${(props) => (props.small ? '14px' : '16px')};
  margin: 1rem 0;
`

const SubTitle1 = styled(Text)`
  font-size: 16px;
  font-weight: 600;
  margin: 0;
`

const SubTitle2 = styled(SubTitle1)`
  font-size: 14px;
`

const Caption = styled(Text)`
  font-size: 13px;
  line-height: 1;
`

const BreakLongWords = styled.span`
  display: table;
  table-layout: fixed;
  width: 100%;
  word-wrap: break-word;
  border-spacing: 0;
`

const Spacer = styled.div<{ size?: number }>`
  margin-bottom: ${(props) => (props.size ? props.size * 1 + 'rem' : '1rem')};
`
const HSpacer = styled.div<{ size: number }>`
  width: ${(props) => (props.size ? props.size * 1 + 'rem' : '1rem')};
`

const Divider = styled.div`
  border-top: 1px solid ${colors.primary};
`

export {
  H1,
  H2,
  H3,
  H4,
  H5,
  Body1,
  Body2,
  SubTitle1,
  SubTitle2,
  Text,
  TextContent,
  Caption,
  Spacer,
  HSpacer,
  Divider,
  BreakLongWords,
}

import { FC, useState } from 'react'
import { BottomArea } from '../BottomArea/BottomArea'
import { Message, Page } from '../components'
import { FsnModal } from '../components/modal/Modal'
import { Config } from '../const'
import { MapComponent } from './MapComponent'
import { TENT_ICON_KEY } from './constants/map'

export const FestivalMap: FC = () => {
  const isTentUnset = !localStorage.getItem(TENT_ICON_KEY)
  const [isModalOpen, setIsModalOpen] = useState(isTentUnset)

  let [userLocation, setUserLocation] = useState<{
    longitude: number
    latitude: number
  } | null>(null)

  return (
    <Page withBackground>
      <MapComponent onUserFound={setUserLocation} />
      <FsnModal
        isOpen={isModalOpen}
        title={<Message id="TENT_TITLE" />}
        text={<Message id="TENT_MESSAGE" />}
        onClose={() => {
          setIsModalOpen(false)
        }}
      ></FsnModal>
      <BottomArea
        userLocation={userLocation}
        showFilterButton={false}
        showMapFinder={Config.REACT_APP_SHOW_MAP_FINDER}
        hasFilters={false}
        resetFilter={() => {}}
        gradientType="light"
      />
    </Page>
  )
}

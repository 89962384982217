import React from 'react'
import { H3, Text, T } from '../components'
import { VERSION } from '../const'
import { LAST_BUILD_TIME } from '../lastBuildTime'
// import { useT } from '../state/language'

const ReloadApp = () => {
  // const t = useT()
  return (
    <div>
      <H3>
        <T id="RELOAD_APP_VERSION_INFO" />
      </H3>
      <Text>
        <T id="RELOAD_APP_CURRENT_VERSION" /> {VERSION}
      </Text>
      <Text>
        <T id="RELOAD_APP_LAST_UPDATE" />{' '}
        {new Date(LAST_BUILD_TIME).toLocaleString()}
      </Text>

      <p>
        <T id="RELOAD_APP_DESC" />
      </p>

      {/* <Button
        onClick={() => {
          if (window.confirm(t('RELOAD_APP_CONFIRMATION'))) {
            window.location.hash = ''
            window.location.reload(true)
          }
        }}
      >
        <T id="RELOAD_APP_BTN" />
      </Button> */}
    </div>
  )
}

export default ReloadApp

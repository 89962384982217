import React from 'react'
import { Row, Col, Button, colors, Message } from '../components'
import styled from 'styled-components/macro'
import { days } from '../state/filter'
import { device } from '../device'

const Container = styled.div<{ disabled?: boolean }>`
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  padding: 1rem 0.75rem;
  background-color: ${colors.backgroundBlack};
  margin-bottom: -4px;
  /* position: sticky;
  top: -1px;
  z-index: ${colors.zIndex.stickyStuff}; */
`

const ButtonCol = styled(Col)`
  align-items: stretch;
  flex-direction: column;
  margin: 0 0.25rem;
  height: 30px;
  width: 30px;

  @media ${device.mobileS} {
    height: 40px;
    width: 40px;
  }

  @media ${device.mobileM} {
    height: 48px;
    width: 48px;
  }
  /* @media ${device.mobileL} {
    height: 50px;
    width: 50px;
  } */
`

const DayButton = styled(Button)<{ activeDay?: boolean }>`
  ${(props) =>
    props.activeDay
      ? `
  background-color: ${colors.secondary};
  
  `
      : `
  background-color: ${colors.backgroundBlack};
  border: 2px solid ${colors.secondary};
  `}

  padding: 0;

  color: ${colors.white};
  border-radius: 35px;
  font-size: 18px;
`

type Props = {
  disabled?: boolean
  day: string
  onDayClick: (day: string) => void
}

const DayButtons = ({ disabled, day, onDayClick }: Props) => (
  <Container disabled={disabled}>
    <Row>
      {days.map((dayItem) => (
        <ButtonCol key={dayItem}>
          <DayButton
            activeDay={dayItem === day}
            onClick={() => {
              onDayClick(dayItem)
            }}
          >
            <Message id="DAY" data={dayItem} />
          </DayButton>
        </ButtonCol>
      ))}
    </Row>
  </Container>
)

export default DayButtons

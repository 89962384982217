export const PLACES = {
  Palapa: { lng: 12.743399454906694, lat: 53.309490863031016 },
  'Roter Platz': { lng: 12.742211729780081, lat: 53.311396681344036 },
  Luftschloss: { lat: 53.3104812, lng: 12.7384233312 },
  'Palast der Republik': { lng: 12.7416072060781, lat: 53.30872407714244 },
  Neuland: { lng: 12.734666290887219, lat: 53.309073447352205 },
  Seebühne: { lng: 12.735537139574404, lat: 53.30983592929789 },
  Triebwerke: { lng: 12.737810223611604, lat: 53.30915402230568 },
  Datscha: { lng: 12.738763113875933, lat: 53.30956511128926 },
  Tubebox: { lng: 12.737916189203247, lat: 53.310505937602585 }, // ?
  'Salon de Baile': { lng: 12.736542406169633, lat: 53.31009583739021 },
  Weidendom: { lng: 12.7338063761772, lat: 53.307941109409455 },
  'Karl Kutter': { lng: 12.735492042062873, lat: 53.30830158893181 },
  Casino: { lng: 12.734032627064948, lat: 53.306629618278265 },
  Hpttrsn: { lng: 12.737279391564556, lat: 53.30907405963387 },
  'Cloud Cuckoo': { lng: 12.736820195358263, lat: 53.308264512533725 },
  'Velvet Soup': { lng: 12.751388146159343, lat: 53.31029754833585 },
  Turmbühne: { lng: 12.737439385002489, lat: 53.30860773504969 },
  Tanzwüste: { lng: 12.73842965869315, lat: 53.31183981249032 },
  Sonnendeck: { lng: 12.743652624683051, lat: 53.3110438852577 },
  'Panne Eichel': { lng: 12.75238589434477, lat: 53.31055815231167 },
  Trancefloor: { lng: 12.742185678000993, lat: 53.31231807081886 },
  Querfeld: { lng: 12.737075729631954, lat: 53.311560337760426 },
  Subardo: { lng: 12.74106304200754, lat: 53.310082847617394 },
  Dubstation: { lng: 12.73460964013276, lat: 53.30755715259582 },
  Extravaganza: { lng: 12.74415023665668, lat: 53.31070211894243 },
  Rootsbase: { lng: 12.743563991852568, lat: 53.31204721621012 },
  Content: { lng: 12.73177729800048, lat: 53.30396413526836 },
  Harbour: { lng: 12.733840018497403, lat: 53.30695791845784 },
  'Harbour Garage': { lng: 12.734339869068265, lat: 53.30693595729127 },
  Oase: { lng: 12.748736447374029, lat: 53.30888317810198 },
  'Workshop Hangar': { lng: 12.732987998313916, lat: 53.30551918283874 },
  Outdoor: { lng: 12.734339869068265, lat: 53.30693595729127 }, // ?
  Schubkarton: { lng: 12.735145891695339, lat: 53.30956869344038 },
  Schuhkarton: { lng: 12.73516881413093, lat: 53.309553766643006 },
  Räuberhöhle: { lng: 12.738236783301176, lat: 53.31090897202819 },
  Tarmac: { lng: 12.736153474251381, lat: 53.308195646046414 }, //?
  Theater: { lng: 12.73513624379126, lat: 53.30867905345224 },
  'Toto Klub': { lng: 12.73273794739714, lat: 53.30206109344593 },
  'Tube Tresen': { lng: 12.738220866081264, lat: 53.31044895116207 },
  'La Ballena': { lng: 12.732625442229477, lat: 53.30164930577797 },
  Kino: { lng: 12.734834176780936, lat: 53.308115964543646 },
  'Grand Palais': { lng: 12.736153474251381, lat: 53.308195646046414 }, // ?
  Kinderspace: { lng: 12.73434834507728, lat: 53.29868105717992 },
  Tower: { lng: 12.739681871537272, lat: 53.301548663463564 },
  Eclipse: { lng: 12.736153474251381, lat: 53.308195646046414 },
  ATM: { lng: 12.739724896803978, lat: 53.30178869230272 },
  'Botschaft 1': { lng: 12.739675020358419, lat: 53.30148780296577 },
  'Botschaft 2': { lng: 12.734292021626374, lat: 53.30091740421324 },
}
export const EXIT_LOCS = [
  { lng: 12.733944043552043, lat: 53.30055715922836 },
  { lng: 12.733349429936624, lat: 53.301521673601826 },
  { lng: 12.732669871518766, lat: 53.3028668809535 },
  { lng: 12.732669871518766, lat: 53.30474502410516 },
  { lng: 12.734538657168372, lat: 53.30484654300298 },
  { lng: 12.735855301603351, lat: 53.30511302896221 },
  { lng: 12.738085102662268, lat: 53.3055064099577 },
  { lng: 12.74014501411628, lat: 53.30584902916203 },
  { lng: 12.744158656010057, lat: 53.30653425931331 },
  { lng: 12.748490840924688, lat: 53.30721947847786 },
  { lng: 12.751124129788195, lat: 53.30766359612923 },
  { lng: 12.7552439526971, lat: 53.30828535309007 },
  { lng: 12.758217020775106, lat: 53.30982067333059 },
  { lng: 12.754032713615004, lat: 53.31111672098859 },
  { lng: 12.751611786751653, lat: 53.31158617380311 },
  { lng: 12.748298939463893, lat: 53.31224593660011 },
  { lng: 12.739897765072271, lat: 53.31278212120398 },
  { lng: 12.735119619946857, lat: 53.3114499141044 },
  { lng: 12.734270171924038, lat: 53.31054906479028 },
  { lng: 12.731077065398921, lat: 53.30942191649012 },
  { lng: 12.731671679014369, lat: 53.30701103581717 },
]

export const WCS_LOCS = [
  { lat: 53.299887042599465, lng: 12.733917007913561 },
  { lat: 53.300170361170814, lng: 12.731909163544628 },
  { lat: 53.3011869593966, lng: 12.730961014815563 },
  { lat: 53.30172024714926, lng: 12.726861665896308 },
  { lat: 53.303220083249954, lng: 12.732160144091011 },
  { lat: 53.301503599803226, lng: 12.733861234458146 },
  { lat: 53.30137027781268, lng: 12.73740284883155 },
  { lat: 53.30167025170536, lng: 12.738406771014837 },
  { lat: 53.300636999426814, lng: 12.74021940829283 },
  { lat: 53.3021535385447, lng: 12.737068208102585 },
  { lat: 53.302670149462784, lng: 12.74044250211091 },
  { lat: 53.3036700238078, lng: 12.737012434648392 },
  { lat: 53.303770009954576, lng: 12.738434657743142 },
  { lat: 53.30448657048913, lng: 12.73790480992318 },
  { lat: 53.30481985036127, lng: 12.73996842774639 },
  { lat: 53.30441991420233, lng: 12.742450346479899 },
  { lat: 53.30520311899872, lng: 12.742366686297316 },
  { lat: 53.30532126554755, lng: 12.748085976047577 },
  { lat: 53.304504734828015, lng: 12.748950464595339 },
  { lat: 53.30520461925778, lng: 12.753077700241704 },
  { lat: 53.30592115571804, lng: 12.752492078967492 },
  { lat: 53.30653770072169, lng: 12.751767024056562 },
  { lat: 53.30797071687539, lng: 12.751292949692015 },
  { lat: 53.308653882064675, lng: 12.749368765505608 },
  { lat: 53.30892048014866, lng: 12.754053735699074 },
  { lat: 53.310420063352126, lng: 12.753914302062327 },
  { lat: 53.31013681278225, lng: 12.752575739150046 },
  { lat: 53.31088658960604, lng: 12.751822797511977 },
  { lat: 53.31076995852044, lng: 12.750093820416538 },
  { lat: 53.31188627155393, lng: 12.748783144231453 },
  { lat: 53.311269803786416, lng: 12.744293381128983 },
  { lat: 53.30993687007171, lng: 12.743986627128294 },
  { lat: 53.309087103105895, lng: 12.744683795312142 },
  { lat: 53.307570810228924, lng: 12.74236919694252 },
  { lat: 53.30708758470013, lng: 12.740695993302523 },
  { lat: 53.306954280143486, lng: 12.738688148933562 },
  { lat: 53.307687450054544, lng: 12.737963094022604 },
  { lat: 53.30813734353933, lng: 12.737489019658085 },
  { lat: 53.308054030288645, lng: 12.736540870929076 },
  { lat: 53.30883716842621, lng: 12.738241961296154 },
  { lat: 53.30928704979584, lng: 12.740500786211555 },
  { lat: 53.31130312659576, lng: 12.74432126785726 },
  { lat: 53.3117196595216, lng: 12.741002747303213 },
  { lat: 53.312119527306464, lng: 12.739552637481268 },
  { lat: 53.311819626818874, lng: 12.737405359475531 },
  { lat: 53.311103189348046, lng: 12.737963094022604 },
  { lat: 53.31052003369271, lng: 12.738576602023926 },
  { lat: 53.30997019392191, lng: 12.738660262206452 },
  { lat: 53.30935369848706, lng: 12.735341374409586 },
  { lat: 53.30897046710646, lng: 12.735118280590285 },
  { lat: 53.30835395723258, lng: 12.734365338952273 },
  { lat: 53.30863721963169, lng: 12.733668170769647 },
  { lat: 53.31000351774847, lng: 12.734727866408349 },
  { lat: 53.307037595542624, lng: 12.732357494584505 },
  { lat: 53.30672099617047, lng: 12.732106514038065 },
  { lat: 53.30575452040736, lng: 12.731018931672224 },
  { lat: 53.30515462789569, lng: 12.734337452225134 },
  { lat: 53.30568786610027, lng: 12.733640284041314 },
  { lat: 53.307054258602676, lng: 12.735006733681871 },
]

export const WATER_LOCS = [
  { lat: 53.29912537156011, lng: 12.734640098710116 },
  { lat: 53.30103332597636, lng: 12.733354779771247 },
  { lat: 53.30140499548844, lng: 12.7299549038689 },
  { lat: 53.301826217024654, lng: 12.727135494583763 },
  { lat: 53.301925327370896, lng: 12.733686474981141 },
  { lat: 53.303387178255065, lng: 12.731862151325998 },
  { lat: 53.3038950977799, lng: 12.732463348894498 },
  { lat: 53.301603217905495, lng: 12.738807019785554 },
  { lat: 53.30502240977336, lng: 12.732649927450325 },
  { lat: 53.305691350073204, lng: 12.733416972623473 },
  { lat: 53.306769065178514, lng: 12.734847408216496 },
  { lat: 53.306731903041765, lng: 12.738744826934123 },
  { lat: 53.307041586528186, lng: 12.740403302983935 },
  { lat: 53.30738842936708, lng: 12.742206895687843 },
  { lat: 53.308069874016184, lng: 12.742808351700603 },
  { lat: 53.307574391292974, lng: 12.743409549269131 },
  { lat: 53.30893856499674, lng: 12.747827936417906 },
  { lat: 53.3086041215941, lng: 12.748159631627857 },
  { lat: 53.30855457420043, lng: 12.749030331554138 },
  { lat: 53.30841831857157, lng: 12.750999771863405 },
  { lat: 53.31031347146046, lng: 12.753632602592603 },
  { lat: 53.31040017578911, lng: 12.75046076714716 },
  { lat: 53.311019487302474, lng: 12.749652260073134 },
  { lat: 53.31054881136819, lng: 12.745166622163993 },
  { lat: 53.311564473998715, lng: 12.744441038892717 },
  { lat: 53.309966652380666, lng: 12.743632531817838 },
  { lat: 53.30908720566464, lng: 12.741517974854474 },
  { lat: 53.31092039806063, lng: 12.7406887368291 },
  { lat: 53.3118369647394, lng: 12.741559436755324 },
  { lat: 53.31215899700882, lng: 12.739320494088389 },
  { lat: 53.311465386025105, lng: 12.739341225038856 },
  { lat: 53.3115025440423, lng: 12.736459622902402 },
  { lat: 53.311254823318194, lng: 12.737475439482694 },
  { lat: 53.31078414998237, lng: 12.737454708532226 },
  { lat: 53.31005335741369, lng: 12.73718520617362 },
  { lat: 53.30917391248377, lng: 12.736190120543768 },
  { lat: 53.30989233364076, lng: 12.738366870359414 },
  { lat: 53.3087527634259, lng: 12.737890058495339 },
  { lat: 53.30895305751537, lng: 12.734219231074093 },
]

export const SHOWER_LOCS = [
  { lat: 53.303465161164354, lng: 12.732170903876522 },
  { lat: 53.30855672727762, lng: 12.74814370716524 },
  { lat: 53.31056398790025, lng: 12.750953454588114 },
  { lat: 53.31160407673741, lng: 12.743990167495554 },
  { lat: 53.3115128418884, lng: 12.739073109503892 },
  { lat: 53.30921365932491, lng: 12.735622006690932 },
  { lat: 53.305454411589665, lng: 12.733453614656867 },
]

export const FEUERWEHR_LOCS = [
  { lat: 53.303361352959854, lng: 12.73272549999976 },
  { lat: 53.30632864346998, lng: 12.7349932999997 },
  { lat: 53.30548754269168, lng: 12.749382099999792 },
  { lat: 53.30971624272976, lng: 12.740740999999758 },
]

export const SPITAL_LOCS = [
  { lat: 53.30315106470542, lng: 12.732686400000063 },
  { lat: 53.307987432553944, lng: 12.735853499999365 },
  { lat: 53.30838459784212, lng: 12.741757599999914 },
]

export const NEARBY_COORDS = {
  Exit: EXIT_LOCS,
  Wc: WCS_LOCS,
  Water: WATER_LOCS,
  Shower: SHOWER_LOCS,
  Feuerwehr: FEUERWEHR_LOCS,
  Spital: SPITAL_LOCS,
}

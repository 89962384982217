let reloadClicks = 0
const maxClicks = 5
const onReloadClick = () => {
  reloadClicks++
  if (reloadClicks === maxClicks) {
    if (
      window.confirm('Reload App. Klicke nur dann OK, wenn du Internet hast.')
    ) {
      window.location.hash = ''
      window.location.reload(true)
    }
  }
}

export default onReloadClick

import { connect } from 'react-redux'
import ArtistInfo from './ArtistInfo'
import {
  getArtistById,
  getArtistWithShows,
  toggleFav,
} from '../state/timetable'

const emptyObj = {}

export default connect(
  (state, props) => {
    const info = getArtistById(props.id) || emptyObj
    // const favorites = getFa
    const artist = getArtistWithShows(state, props.id) || emptyObj
    return {
      info,
      artist,
    }
  },
  {
    toggleFav,
  }
)(ArtistInfo)

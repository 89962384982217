import throttle from 'lodash/throttle'
// import * as R from 'ramda'
import { saveState } from '../helper/LocalStorage'

/**
 * Throttled function to save the state to localStorage not more then once every 3 sec
 */
const makeSaveToLocalStorage = delay =>
  throttle((state, save = saveState) => {
    // const reducedState = R.omit(
    //   // ['locales', 'topContracts', 'topHardware', 'form'],
    //   state
    // )
    // console.log('save state', state)
    save(state)
  }, delay)

const SAVE_DELAY_TIME = 1000

export default function saveStoreMiddleWare({
  getState,
  save = makeSaveToLocalStorage(SAVE_DELAY_TIME),
}) {
  return next => action => {
    // console.log('will dispatch', action)
    // console.log('process.env.NODE_ENV: ', process.env.NODE_ENV)

    // Call the next dispatch method in the middleware chain.
    let returnValue = next(action)

    // save state ONLY in dev mode and not for all actions
    // if (process.env.NODE_ENV === 'development') {
    //   if (
    //     !R.contains(action.type, [
    //       'COUNTDOWN/TICK',
    //       'SESSION/SESSION_USER_ACTIVITY',
    //       'COUNTDOWN/START',
    //     ])
    //   ) {
    //     save(getState())
    //   }
    // }
    save(getState())

    // This will likely be the action itself, unless
    // a middleware further in chain changed it.
    return returnValue
  }
}

export const __test__ = {
  makeSaveToLocalStorage,
  SAVE_DELAY_TIME,
}

import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  FilterButton,
  FilterContainer,
  ScrollableContent,
  SectionTitle,
} from '../FilterMenu/FilterMenu'
import { Overlay } from '../Menu/Menu'
import { Message, Page, Spacer } from '../components'
import { PageTitle } from '../components/PageTitle'
import { floors } from '../state/filter/consts'
import { NE, SW } from './constants/map'
import { facilities, nearbyPlaces } from './constants/places'
import { isPointInBounds } from './helpers/map'
import { Coordinate } from './models/map'

type Props = {
  onClose: () => void
  userLocation: Coordinate | null | undefined
}

export const MapFinder: FC<Props> = ({ onClose, userLocation }) => {
  const navigate = useNavigate()

  const isUserAtFusion =
    userLocation && isPointInBounds(userLocation, { NE, SW })

  return (
    <Overlay isVisible={true}>
      <Page withSolidBackground alignLeft center>
        <PageTitle title={<Message id="TITLE_PAGE_FILTER" />} />
        <ScrollableContent>
          {isUserAtFusion && (
            <>
              <SectionTitle>
                <Message id="NEARBY" />
              </SectionTitle>

              <FilterContainer>
                {nearbyPlaces.map((nearbyPlace) => (
                  <FilterButton
                    key={nearbyPlace}
                    onClick={() => {
                      navigate('/map?nearby=' + nearbyPlace)
                      onClose()
                    }}
                  >
                    {nearbyPlace}
                  </FilterButton>
                ))}
              </FilterContainer>
            </>
          )}
          <SectionTitle>
            <Message id="PLACES" />
          </SectionTitle>
          <FilterContainer>
            {facilities.map((facility) => (
              <FilterButton
                key={facility}
                onClick={() => {
                  navigate('/map?place=' + facility)
                  onClose()
                }}
              >
                {facility}
              </FilterButton>
            ))}
          </FilterContainer>
          <>
            <Spacer size={2} />
            <SectionTitle>
              <Message id="FILTER_FLOORS" />
            </SectionTitle>
            <FilterContainer>
              {floors.map((floor) => (
                <FilterButton
                  key={floor}
                  onClick={() => {
                    navigate('/map?place=' + floor)
                    onClose()
                  }}
                >
                  {floor}
                </FilterButton>
              ))}
            </FilterContainer>
          </>
        </ScrollableContent>
      </Page>
    </Overlay>
  )
}

import { FC } from 'react'
import styled from 'styled-components/macro'
import MenuIcon from '../images/icons_2023/menu-big.svg'
import MenuCloseIcon from '../images/icons_2023/menu-close-big.svg'

const MenuButtonBase = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s ease-out, visibility 0.2s ease-out;
  width: 56px;
  height: 56px;
  z-index: 10;
  img {
    width: 56px;
    height: 56px;
  }

  svg {
    width: 56px;
    height: 56px;
  }
  pointer-events: auto;
`

type Props = {
  onClick?: () => void
  isOpen?: boolean
}

export const MenuButton: FC<Props> = ({ isOpen, onClick }) => {
  return (
    <>
      <MenuButtonBase onClick={onClick}>
        <img alt="Menu" src={isOpen ? MenuCloseIcon : MenuIcon} />
      </MenuButtonBase>
    </>
  )
}

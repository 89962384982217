import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components/macro'
import Entries from './Entries'
import Selection from './Selection'
import { Row, Message, Button } from '../components'
import { PageTitle } from '../components/PageTitle'
import { getTimeString } from '../helper/DateFornat'

const Page = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
`

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 10rem;
`
const BottomButton = styled.div`
  padding: 2rem;
`

export type TimetableListProps = {
  navigate: any
  isLoading: boolean
  type?: 'favorites' | 'timetable'
  orderBy: 'name' | 'time'
  loadTimeTable: () => void
  toggleScrollButton: (showTopButton: boolean) => void
  entries: any
  selection: any
  toggleFavorite: (id: string) => void
  toggleFav: (id: string) => void
  day: string
  changeFilter: () => void
  openFilter: () => void
  filters: any
}

type State = {
  showTopButton: boolean
}

const titles = {
  favorites: <Message id="BUTTON_FAVORITES" />,
  timetable: <Message id="BUTTON_TIMETABLE" />,
  artists: <Message id="BUTTON_ARTISTS" />,
}

const daysToNumber = {
  So: 0,
  Mo: 1,
  Di: 2,
  Mi: 3,
  Do: 4,
  Fr: 5,
  Sa: 6,
}

class TimetableList extends React.Component<TimetableListProps, State> {
  scrollStepInPx: number
  delayInMs: number
  intervalId: number | undefined
  domNode: any
  contentDiv: any

  constructor(props) {
    super(props)

    this.state = {
      showTopButton: false,
    }

    this.scrollStepInPx = 50
    this.delayInMs = 16.66
  }

  componentDidMount() {
    console.log('componentDidMount()')
    this.props.loadTimeTable()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log('componentDidUpdate()')
    if (
      prevProps.type !== this.props.type ||
      prevProps.day !== this.props.day
    ) {
      // console.log('scroll to top()', this.contentDiv)
      this.scrollToTop()
    }
  }
  // eslint-disable-next-line no-undef
  scrollStep = () => {
    if (!this.domNode) {
      return
    }

    if (this.domNode.scrollTop === 0) {
      window.clearInterval(this.intervalId)
    }
    this.domNode.scrollTop = this.domNode.scrollTop - this.scrollStepInPx
  }

  // eslint-disable-next-line no-undef
  scrollToTop = () => {
    // this.scrollStepInPx = 5 * this.props.entries.length

    // let intervalId = window.setInterval(this.scrollStep, this.delayInMs)
    // this.intervalId = intervalId

    if (this.domNode) {
      this.domNode.scrollTop = 0
    }
  }
  // eslint-disable-next-line no-undef
  // checkScroll = () => {
  //   const distance = 400
  //   if (this.domNode) {
  //     const scrollPos = this.domNode.scrollTop
  //     // console.log('scrollPos: ' + scrollPos)
  //     if (scrollPos > distance && !this.state.showTopButton) {
  //       this.setState({ showTopButton: true })
  //       this.props.toggleScrollButton(true)
  //     }
  //     if (scrollPos < distance && this.state.showTopButton) {
  //       this.setState({ showTopButton: false })
  //       this.props.toggleScrollButton(false)
  //     }
  //   }
  // }
  // eslint-disable-next-line no-undef
  saveRef = (ref) => {
    this.contentDiv = ref

    const domNode = ReactDOM.findDOMNode(this.contentDiv)
    if (domNode) {
      this.domNode = domNode
      // domNode.addEventListener('scroll', this.checkScroll)
    }
  }

  render() {
    const {
      entries,
      type = 'timetable',
      orderBy,
      toggleFav,
      day,
      changeFilter,
      openFilter,
      filters,
    } = this.props

    const isFavoritesList = type === 'favorites'
    const isSearch = !!filters.search && !isFavoritesList

    const now = new Date()
    const isCurrentDay = now.getDay() === daysToNumber[day]
    now.setMinutes(0)
    const currentTime = getTimeString(now)

    return (
      <Page>
        <Content id="myContent" ref={this.saveRef}>
          <PageTitle title={titles[type]} />
          <Selection
            isSticky
            day={day}
            filters={filters}
            changeFilter={changeFilter}
            openFilter={openFilter}
            scrollToTop={this.scrollToTop}
            alwaysDayButtons={isFavoritesList && orderBy === 'time'}
            hideDayButtons={orderBy === 'name'}
            onlyDayButtons={isFavoritesList}
            showFloors={orderBy === 'time'}
          />

          <Entries
            isLoading={this.props.isLoading}
            entries={entries}
            day={day}
            toggleFav={toggleFav}
            type={type}
            orderBy={orderBy}
            openFilter={openFilter}
            changeFilter={changeFilter}
            isSearch={isSearch}
            scrollToTime={isCurrentDay ? currentTime : undefined}
          />

          {entries.length > 0 && !filters.search && (
            <>
              <Selection
                isSticky={false}
                day={day}
                filters={filters}
                changeFilter={changeFilter}
                openFilter={openFilter}
                scrollToTop={this.scrollToTop}
                alwaysDayButtons={isFavoritesList && orderBy === 'time'}
                hideDayButtons={orderBy === 'name'}
                onlyDayButtons
                showFloors={orderBy === 'time'}
              />
            </>
          )}

          {isFavoritesList && (
            <BottomButton>
              <Row>
                <Button onClick={() => this.props.navigate('/importExport')}>
                  <Message id="IMPORT_EXPORT_BTN" />
                </Button>
              </Row>
            </BottomButton>
          )}
        </Content>
      </Page>
    )
  }
}

export default TimetableList

import React from 'react'
import FilterSelect from './FilterSelect'
import { types, floors } from '../state/filter/consts'
import { CSSTransition } from 'react-transition-group'
import './FilterSelects.css'
import SearchInput from './SearchInput'
import ArtistInfo from '../ArtistInfo'
import { FloorInfo } from '../FloorInfo/FloorInfo'

const FilterSelects = ({
  filters,
  openFilter,
  closeFilter,
  changeFilter,
  resetFilter,
}) => (
  <React.Fragment>
    <CSSTransition
      in={filters.openedFilter === 'types'}
      timeout={300}
      classNames="types"
      unmountOnExit
      mountOnEnter
      // onExited={}
    >
      {state => {
        // console.log('types state: ', state)
        // if (state === 'exited') {
        //   return null
        // }
        return (
          <div className="types">
            <FilterSelect
              filterId="types"
              options={types}
              show={state !== 'exited'}
              value={filters.types}
              onClose={closeFilter}
              onReset={() => resetFilter('types')}
              onChange={value => {
                changeFilter({ filterId: 'types', value })
              }}
            />
          </div>
        )
      }}
    </CSSTransition>

    <CSSTransition
      in={filters.openedFilter === 'floors'}
      timeout={300}
      classNames="floors"
      mountOnEnter
      unountOnExit
      // onExited={}
    >
      {state => {
        // console.log('floors state: ', state)
        // if (state === 'exited') {
        //   return null
        // }
        return (
          <div className="floors">
            <FilterSelect
              filterId="floors"
              options={floors}
              show={state !== 'exited'}
              value={filters.floors}
              onClose={closeFilter}
              openFilter={openFilter}
              changeFilter={changeFilter}
              onReset={() => resetFilter('floors')}
              onChange={value => {
                changeFilter({ filterId: 'floors', value })
              }}
            />
          </div>
        )
      }}
    </CSSTransition>

    <CSSTransition
      in={filters.openedFilter === 'search'}
      timeout={300}
      classNames="search"
      mountOnEnter
      unountOnExit
      // onExited={}
    >
      {state => {
        // console.log('floors state: ', state)
        // if (state === 'exited') {
        //   return null
        // }
        return (
          <div className="search">
            <SearchInput
              show={state !== 'exited'}
              value={filters.search}
              onClose={closeFilter}
              // onReset={() => resetFilter('search')}
              onChange={value => {
                changeFilter({ filterId: 'search', value })
              }}
            />
          </div>
        )
      }}
    </CSSTransition>

    <CSSTransition
      in={filters.openedFilter === 'info'}
      timeout={300}
      classNames="info"
      mountOnEnter
      unountOnExit
      // onExited={}
    >
      {state => {
        return (
          <div className="info">
            <ArtistInfo
              show={state !== 'exited'}
              id={filters.info}
              onClose={closeFilter}
            />
          </div>
        )
      }}
    </CSSTransition>

    <CSSTransition
      in={filters.openedFilter === 'floorInfo'}
      timeout={300}
      classNames="floorInfo"
      mountOnEnter
      unountOnExit
      // onExited={}
    >
      {state => {
        // console.log('floorInfo state: ', state)
        if (state === 'exited') {
          return null
        }

        return (
          <div className="floorInfo">
            <FloorInfo
              show={state !== 'exited'}
              floor={filters.floorInfo}
              // onClose={closeFilter}
              onClose={() => openFilter('floors')}
            />
          </div>
        )
      }}
    </CSSTransition>
  </React.Fragment>
)

export default FilterSelects

import { combineReducers } from 'redux'
import timetable from './timetable'
import filter from './filter'
// import language from './language'

// import { reducer as formReducer } from 'redux-form'

export default function createReducer(asyncReducers) {
  return combineReducers({
    timetable,
    filter,
    // language,
  })
}

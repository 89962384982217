import { connect } from 'react-redux'
import ImportExport from './ImportExport'
import { getFavorites, importFavs } from '../state/timetable'

export default connect(
  (state, props) => {
    return {
      favorites: getFavorites(state),
      // favoritesJSON: '[1,2,3,4]',
    }
  },
  {
    importFavs,
  }
)(ImportExport)

import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components/macro'
import colors from '../colors'
import { ReactComponent as Plus } from '../../images/plus.svg'

const InputContainer = styled.div`
  background: none;
  border: 1px solid white;
  font-size: 14px;
  color: white;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  &:focus {
    background-color: black;
  }
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  border-radius: 25px;
`

const InputElement = styled.input`
  padding: 12px 1rem;
  margin: 0;
  flex: 1;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 18px;
  min-width: 0;
  ::placeholder {
    color: #999;
  }
`

const CloseButton = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  margin-right: 8px;
  background: 'red';
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: ${colors.primary};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  &:active {
    background-color: ${colors.highlight};
  }
`

const CloseIcon = styled(Plus)`
  transform: rotate(45deg);
  fill: white;
`

class Input extends React.Component {
  // eslint-disable-next-line
  onChange = event => {
    let value = event.target.value
    this.props.onChange(value)
  }

  // eslint-disable-next-line
  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.props.onEnter()
      if (this.inputField) {
        const domNode = ReactDOM.findDOMNode(this.inputField)
        domNode.blur()
      }
    }
  }

  render() {
    let {
      placeholder,
      disabled,
      value,
      className,
      autoFocus = true,
    } = this.props
    return (
      <InputContainer disabled={disabled} className={className}>
        <InputElement
          value={value}
          placeholder={placeholder}
          onChange={this.onChange}
          onKeyPress={this.handleKeyPress}
          ref={ref => (this.inputField = ref)}
          autoFocus={autoFocus}
        />
        <CloseButton disabled={!value} onClick={() => this.props.onChange('')}>
          <CloseIcon />
        </CloseButton>
      </InputContainer>
    )
  }
}

export default Input

import { NEARBY_COORDS } from '../constants/coordinates'
import { Coordinate, FsnCoord, FsnLatLng, NearbyType } from '../models/map'

export function isPointInBounds(
  point: Coordinate,
  bounds: {
    NE: FsnCoord
    SW: FsnCoord
  }
): boolean {
  const eastBound = point.longitude < bounds.NE[0]
  const westBound = point.longitude > bounds.SW[0]

  let inLong

  if (bounds.NE[0] < bounds.SW[0]) {
    inLong = eastBound || westBound
  } else {
    inLong = eastBound && westBound
  }

  const inLat = point.latitude > bounds.SW[1] && point.latitude < bounds.NE[1]

  return inLat && inLong
}

export function findNearestPlace(
  targetCoordinate: FsnLatLng,
  type: NearbyType
): FsnLatLng | null {
  const coordinates: FsnLatLng[] = NEARBY_COORDS[type]

  let nearestCoordinate: FsnLatLng | null = null
  let nearestDistance: number = Infinity

  if (coordinates && coordinates.length > 0) {
    for (const coordinate of coordinates) {
      const distance = getDistance(targetCoordinate, coordinate)

      if (distance < nearestDistance) {
        nearestDistance = distance
        nearestCoordinate = coordinate
      }
    }
  }

  return nearestCoordinate
}

function getDistance(coord1: FsnLatLng, coord2: FsnLatLng): number {
  const latDiff = coord1.lat - coord2.lat
  const lonDiff = coord1.lng - coord2.lng

  return Math.sqrt(latDiff * latDiff + lonDiff * lonDiff)
}

import React from 'react'
import styled from 'styled-components/macro'
import Input from '../components/Input/Input'
import { Button, Message } from '../components'

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 1);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  z-index: 1000;
`

const OkButton = styled(Button)`
  flex: 0 1 auto;
  margin-top: 1rem;
`

class SearchInput extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: this.props.value,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ value: nextProps.value })
  }

  // eslint-disable-next-line
  onChange = (value) => {
    this.setState({ value })
  }
  // eslint-disable-next-line
  onClear = () => {
    let value = ''
    this.setState({ value })
    this.props.onChange(value)
  }

  // eslint-disable-next-line
  onClose = () => {
    this.props.onChange(this.state.value)
    this.props.onClose()
  }

  render() {
    let { show } = this.props
    return (
      <Overlay show={show}>
        <Input
          placeholder="Artist / Floor"
          value={this.state.value}
          onChange={this.onChange}
          onEnter={this.onClose}
        />
        <OkButton onClick={this.onClose} disabled={!this.state.value}>
          <Message id="SEARCH_OK_BTN" />
        </OkButton>
        <OkButton onClick={this.onClose}>
          <Message id="SEARCH_CLOSE_BTN" />
        </OkButton>
      </Overlay>
    )
  }
}

export default SearchInput

import React, { FC } from 'react'
import styled from 'styled-components/macro'
import { colors } from '../components'
import { ReactComponent as Plus } from '../images/icons_2023/add.svg'
import { ReactComponent as InfoIcon } from '../images/icons_2023/artist-details.svg'

type FavoriteProps = {
  isFavorite?: boolean
}

type PlusButtonProps = FavoriteProps & {
  onClick?: () => void
}

const SPlusButton = styled.div<FavoriteProps>`
  /* background-color: ${(props) =>
    props.isFavorite ? colors.plusButtonFavorite : colors.plusButton};

  color: ${(props) =>
    props.isFavorite ? colors.plusButton : colors.plusButtonFavorite}; */
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  user-select: none;
  box-sizing: border-box;

  svg {
    fill: ${(props) =>
      props.isFavorite ? colors.plusButton : colors.plusButtonFavorite};
    width: 32px;
    height: 32px;
    /* transform: scale3d(1.3, 1.3, 1.3); */
  }
`

export const SPlusIcon = styled.div<FavoriteProps>`
  transform: ${(props) =>
    props.isFavorite ? 'rotate(45deg)' : 'rotate(0deg)'};
  transition: transform 0.2s ease-out;
  width: 32px;
  height: 32px;

  & .bg {
    fill: ${(props) => (props.isFavorite ? '#F6DBC6' : '#FA5A00')};
  }

  & .content {
    fill: ${(props) => (props.isFavorite ? colors.plusButton : colors.white)};
  }
`

export const PlusButton: FC<PlusButtonProps> = ({ isFavorite, onClick }) => {
  return (
    <SPlusButton
      isFavorite={isFavorite}
      onClick={(event) => {
        onClick && onClick()
        event.preventDefault()
      }}
    >
      <SPlusIcon isFavorite={isFavorite}>
        <Plus />
      </SPlusIcon>
    </SPlusButton>
  )
}

export const SInfoButton = styled(SPlusButton)`
  /* border: 1px solid ${colors.white}; */
  background: none;
  /* & svg {
    width: 10px;
  } */
`

export const InfoButton: FC<PlusButtonProps> = ({ onClick }) => {
  return (
    <SInfoButton onClick={onClick}>
      <InfoIcon />
    </SInfoButton>
  )
}

import { useCallback } from 'react'
import { useT } from '../state/language'

const useShare = body => {
  const t = useT()
  const share = useCallback(() => {
    // console.log('share: ', navigator.share)
    if (navigator.share) {
      navigator
        .share({
          title: t('FAVORITES_SHARE_TITLE'),
          text: body,
          // url: 'https://timetable.fusion.de',
        })
        .then(() => console.log('Successful share'))
        .catch(error => console.log('Error sharing', error))
    }
  }, [body, t])

  return share
}

export default useShare

import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import KeyVisualImg from '../images/key_visual_2023.svg'
import {
  Page,
  Content,
  Row,
  Spacer,
  Button,
  Text,
  T,
  BottomDocked,
} from '../components'
import { VERSION } from '../const'
import onReloadClick from './reloadAppClick'
import { useSetLang } from '../state/language'
import { LAST_BUILD_TIME } from '../lastBuildTime'

import { connect } from 'react-redux'
import { importFavs } from '../state/timetable'
import { useNavigate, useParams } from 'react-router-dom'

const VersionText = styled.div`
  font-size: 12px;
  color: #6e6e6e;
  padding: 0.5rem 0;
`

const KeyVisualContainer = styled.div`
  margin: 2rem -1rem 1rem;
`
const KeyVisual = styled.img`
  max-width: 100%;
  max-height: 50vh;
  width: 100%;
`

const BottomDockedStyled = styled(BottomDocked)`
  padding-bottom: calc(0px + env(safe-area-inset-bottom) * 4);
`

const LanguageSelection = ({ importFavs }) => {
  const setLang = useSetLang()
  const lastUpdateStr = new Date(LAST_BUILD_TIME).toLocaleString()
  const [favsImported, setFavsImported] = useState(-1)
  const [clicks, setClicks] = useState(0)
  let navigate = useNavigate()
  let params = useParams()

  let code = params.code

  useEffect(() => {
    if (code) {
      try {
        // let codeStr = decodeURIComponent(code)
        // const favs = JSON.parse(codeStr)
        const favs = code.split('-')
        // console.log('Import Favs: ', favs)
        importFavs(favs)
        setFavsImported(favs.length)
        window.location.hash = ''
      } catch (error) {}
    }
  }, [code, importFavs])

  useEffect(() => {
    if (clicks > 5) {
      setLang('de')
      navigate('/start')
    }
  }, [clicks, navigate, setLang])

  return (
    <Page>
      <Content>
        <KeyVisualContainer onClick={onReloadClick}>
          <KeyVisual src={KeyVisualImg} />
        </KeyVisualContainer>
        {favsImported > 0 && (
          <Text>
            {favsImported} <T id="FAVORITES_IMPORT_SUCCESS" />
          </Text>
        )}
      </Content>

      <BottomDockedStyled className="ios-extra-padding">
        <Row flexDirection="column" alignItems="initial">
          {process.env.REACT_APP_IS_NOT_REALEASED === 'true' ? (
            <div>not released</div>
          ) : (
            <>
              <Button
                onClick={() => {
                  setLang('de')
                  navigate('/start')
                }}
              >
                Deutsch
              </Button>
              <Spacer />
              <Button
                onClick={() => {
                  setLang('en')
                  navigate('/start')
                }}
              >
                English
              </Button>
            </>
          )}

          <VersionText onClick={() => setClicks((click) => click + 1)}>
            V {VERSION} {lastUpdateStr}
          </VersionText>
        </Row>
      </BottomDockedStyled>
    </Page>
  )
}

export default connect(null, {
  importFavs,
})(LanguageSelection)

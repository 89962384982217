import React from 'react'
import styled from 'styled-components/macro'
import TimetableListContainer from '.'
import FilterSelectsContainer from '../Filter/FilterSelectsContainer'
import { useNavigate } from 'react-router-dom'
import { colors } from '../components'
import { BottomAreaContainer } from '../BottomArea/BottomAreaContainer'

const Container = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
  flex-direction: column;
  background-color: ${colors.backgroundBlack};
`

const ContainerInner = styled.div`
  width: 100%;
  height: 100%;
  /* height: calc(100% - 52px); */
  display: flex;
`

type Props = {
  navigate: any
  type?: 'favorites' | 'artists' | 'timetable'
}

type State = {
  leftScrollButton: boolean
  rightScrollButton: boolean
}

class SwitcherInner extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      leftScrollButton: false,
      rightScrollButton: false,
    }
  }
  // eslint-disable-next-line
  // onEmergencyClick = () => {
  //   this.props.navigate('/notfall')
  // }

  // eslint-disable-next-line
  // onScrollUpClick = () => {
  //   const ref = this.refs.left
  //   // @ts-ignore
  //   ref.scrollToTop()
  // }
  // eslint-disable-next-line
  toggleScrollButtonLeft = (visible) => {
    this.setState({ leftScrollButton: visible })
  }

  render() {
    const { navigate, type } = this.props

    return (
      <Container>
        <ContainerInner>
          <TimetableListContainer
            // @ts-ignore
            type={type}
            navigate={navigate}
            // ref="left"
            // toggleScrollButton={this.toggleScrollButtonLeft}
            selection={undefined}
            toggleFavorite={function (id: string): void {
              throw new Error('Function not implemented.')
            }}
          />
        </ContainerInner>
        <FilterSelectsContainer />
        <BottomAreaContainer
          showScrollUpButton={this.state.leftScrollButton}
          // onScrollUpClick={this.onScrollUpClick}
        />
      </Container>
    )
  }
}

const Switcher = ({
  type,
}: {
  type?: 'favorites' | 'artists' | 'timetable'
}) => {
  const navigate = useNavigate()
  return <SwitcherInner navigate={navigate} type={type} />
}

export default Switcher

import { ns } from './actions'
import { days } from './consts'
import { createSelector } from 'reselect'

export const getFilters = ns.getState

export const getDay = (state) => getFilters(state).days
export const getSearch = (state) => getFilters(state).search

export const hasFilters = createSelector(getFilters, (filters) => {
  const { types, floors } = filters
  return types.length > 0 || floors.length > 0
})


export const getNextDay = createSelector(
  getDay,
  (state) => days,
  (day, days) => {
    console.log('days 2: ', days)

    const index = days.indexOf(day)
    // debugger
    const nextIndex = index + 1
    return days[nextIndex]
  }
)

export const getPrevDay = createSelector(getDay, (day) => {
  const index = days.indexOf(day)
  const prevIndex = index - 1
  return days[prevIndex]
})

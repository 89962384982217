import { FC } from 'react'
import styled from 'styled-components/macro'
import { Overlay } from '../Menu/Menu'
import { H5, Message, Page, Spacer, colors } from '../components'
import { PageTitle } from '../components/PageTitle'
import { Config } from '../const'
import { floors, types } from '../state/filter/consts'

export const FilterButton = styled.button<{ isActive?: boolean }>`
  font-size: 16px;
  padding: 10px 16px;
  padding: 0.8rem;
  color: ${colors.white};
  color: ${colors.textInverted};
  background-color: ${(props) =>
    props.isActive ? colors.primary : colors.backgroundBlack};
  border: 1px solid ${colors.primary};
  border-radius: 8px;
  cursor: pointer;
`

export const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 2rem 1rem;
`

export const SectionTitle = styled(H5)`
  background-color: ${colors.Filters.titleBackground};
  padding: 1rem 0;
  text-align: center;
  border-bottom: 1px solid ${colors.Filters.border};
`

export const ScrollableContent = styled.div`
  /* flex: 1, */
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 5rem;
`

type Props = {
  isVisible?: boolean
  onClose: () => void
  filters: Record<string, string[]>
  changeFilter: (filter: { filterId: string; value: string }) => void
  resetFilter: (filterId: string) => void
}

const showFloors = !Config.REACT_APP_SHOW_ARTISTS

export const FilterMenu: FC<Props> = ({
  isVisible,
  onClose,
  filters,
  changeFilter,
  resetFilter,
}) => {
  return (
    <Overlay isVisible={isVisible}>
      <Page withSolidBackground alignLeft center>
        <PageTitle title={<Message id="TITLE_PAGE_FILTER" />} />
        <ScrollableContent>
          <SectionTitle>
            <Message id="FILTER_GENRE" />
          </SectionTitle>

          <FilterContainer>
            {types.map((type) => (
              <FilterButton
                key={type}
                isActive={filters.types.includes(type)}
                onClick={() => changeFilter({ filterId: 'types', value: type })}
              >
                {type}
              </FilterButton>
            ))}
          </FilterContainer>

          {showFloors && (
            <>
              <Spacer size={2} />
              <SectionTitle>
                <Message id="FILTER_FLOORS" />
              </SectionTitle>
              <FilterContainer>
                {floors.map((floor) => (
                  <FilterButton
                    key={floor}
                    isActive={filters.floors.includes(floor)}
                    onClick={() =>
                      changeFilter({ filterId: 'floors', value: floor })
                    }
                  >
                    {floor}
                  </FilterButton>
                ))}
              </FilterContainer>
            </>
          )}
        </ScrollableContent>
      </Page>
    </Overlay>
  )
}

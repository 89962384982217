import styled from 'styled-components/macro'

type RowProps = {
  flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
  alignItems?:
    | 'flex-start'
    | 'center'
    | 'flex-end'
    | 'baseline'
    | 'stretch'
    | 'initial'
  justifyContent?:
    | 'flex-start'
    | 'center'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
  margin?: string
  align?: 'left' | 'center' | 'right'
}

export const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : 'row'};
  align-items: ${(props) => props.alignItems || 'center'};
  justify-content: ${(props) => props.justifyContent || 'center'};
  margin: ${(props) => props.margin || 'initial'};
`

type ColProps = {
  flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
  flex?: number
  align?: 'left' | 'center' | 'right'
}

export const Col = styled.div<ColProps>`
  display: flex;
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : 'row'};
  flex: ${(props) => (props.flex ? props.flex : 'initial')};
  text-align: ${(props) => props.align || 'left'};
`

import { FsnCoord } from '../models/map'

export const FUSION_MAP_BOUNDS: [FsnCoord, FsnCoord, FsnCoord, FsnCoord] = [
  [12.72621, 53.31331],
  [12.76281, 53.31331],
  [12.76421, 53.29721],
  [12.72571, 53.29771],
]

export const FUSION_CENTER: any = [12.744, 53.30445]

export const TENT_INITIAL_POSITION: any = [
  12.738932785000912, 53.30189000011734,
]

export const NE: FsnCoord = [
  FUSION_MAP_BOUNDS[1][0] + 0.001,
  FUSION_MAP_BOUNDS[1][1] + 0.001,
]

export const SW: FsnCoord = [
  FUSION_MAP_BOUNDS[3][0] - 0.001,
  FUSION_MAP_BOUNDS[3][1] - 0.001,
]

export const MAX_NE: FsnCoord = [
  FUSION_MAP_BOUNDS[1][0] + 0.05,
  FUSION_MAP_BOUNDS[1][1] + 0.05,
]
export const MAX_SW: FsnCoord = [
  FUSION_MAP_BOUNDS[3][0] - 0.05,
  FUSION_MAP_BOUNDS[3][1] - 0.05,
]

export const TENT_ICON_KEY = 'tentPosition'
export const LAYERS_VISIBILITY = 'layersVisibility'
export const USER_AREA = 'userArea'
export const OUTSIDE_OF_FSN = 'userIsOutside'
export const INSIDE_OF_MAP = 'insideOfMap'

export const STEPS = 400

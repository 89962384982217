import { Map } from 'maplibre-gl'
import { FUSION_MAP_BOUNDS } from './constants/map'
import { FsnLayerId } from './models/map'

import exitsLayer from '../images/map-layers/exits.webp'
import festivalLayer from '../images/map-layers/festival.webp'
import floorsLayer from '../images/map-layers/floors.webp'
import waterLayer from '../images/map-layers/water.webp'
import wcLayer from '../images/map-layers/wc.webp'

export const FESTIVAL: FsnLayerId = 'festival'
export const FESTIVAL_SRC = `${FESTIVAL}-src`

export const FLOORS: FsnLayerId = 'floors'
export const FLOORS_SRC = `${FLOORS}-src`

export const WATER: FsnLayerId = 'water'
export const WATER_SRC = `${WATER}-src`

export const WC: FsnLayerId = 'wc'
export const WC_SRC = `${WC}-src`

export const EXITS: FsnLayerId = 'exits'
export const EXITS_SRC = `${EXITS}-src`

export const addLayers = (map: Map) => {
  map.addSource(FESTIVAL_SRC, {
    type: 'image',
    url: festivalLayer,
    coordinates: FUSION_MAP_BOUNDS,
  })

  map.addLayer({
    id: FESTIVAL,
    source: FESTIVAL_SRC,
    type: 'raster',
  })

  map.addSource(FLOORS_SRC, {
    type: 'image',
    url: floorsLayer,
    coordinates: FUSION_MAP_BOUNDS,
  })

  map.addLayer({
    id: FLOORS,
    source: FLOORS_SRC,
    type: 'raster',
  })

  map.addSource(WATER_SRC, {
    type: 'image',
    url: waterLayer,
    coordinates: FUSION_MAP_BOUNDS,
  })

  map.addLayer({
    id: WATER,
    source: WATER_SRC,
    type: 'raster',
  })

  map.addSource(WC_SRC, {
    type: 'image',
    url: wcLayer,
    coordinates: FUSION_MAP_BOUNDS,
  })

  map.addLayer({
    id: WC,
    source: WC_SRC,
    type: 'raster',
  })

  map.addSource(EXITS_SRC, {
    type: 'image',
    url: exitsLayer,
    coordinates: FUSION_MAP_BOUNDS,
  })

  map.addLayer({
    id: EXITS,
    source: EXITS_SRC,
    type: 'raster',
  })
}

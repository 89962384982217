import { connect } from 'react-redux'
import { toggleFav, loadTimeTable, isLoading } from '../state/timetable'
import { getEntriesForArtists, getEntriesForHours } from './selectors'
import TimetableList from './TimetableList'
import {
  getPrevDay,
  getNextDay,
  getDay,
  getFilters,
} from '../state/filter/selectors'
import {
  changeFilter,
  closeFilter,
  openFilter,
  resetFilter,
} from '../state/filter/actions'
import { Config } from '../const'

export default connect(
  (state, props) => {
    // @ts-ignore
    const isFavoritesList = props.type === 'favorites'

    const orderBy = Config.REACT_APP_SHOW_ARTISTS ? 'name' : 'time'

    return {
      // @ts-ignore
      entries:
        orderBy === 'name'
          ? // @ts-ignore
            getEntriesForArtists(state, isFavoritesList)
          : // @ts-ignore
            getEntriesForHours(state, isFavoritesList),
      isFavoritesList,

      isLoading: isLoading(state),
      // @ts-ignore
      prevDay: getPrevDay(state),
      // @ts-ignore
      nextDay: getNextDay(state),
      day: getDay(state),
      filters: getFilters(state),
      orderBy,
    }
  },
  {
    toggleFav,
    loadTimeTable,
    changeFilter,
    closeFilter,
    openFilter,
    resetFilter,
  },
  null,
  {
    forwardRef: true,
  }
)(TimetableList)

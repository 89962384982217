import * as R from 'ramda'

export const makeNamespace = (...nameList) => {
  return {
    getType: type => nameList.join('/') + '/' + type, // deprecated
    getState: state => R.path(nameList, state), // deprecated
    createActionName: type => nameList.join('/') + '/' + type,
    extractState: state => R.path(nameList, state),
    createFullState: state =>
      R.reduceRight((value, acc) => ({ [value]: acc }), state, nameList),
  }
}

import React from 'react'
import styled from 'styled-components/macro'
import {
  Page,
  Content,
  H3,
  Text,
  Spacer,
  Message,
  Row,
  BottomDocked,
  Button,
} from '../components'
import { useNavigate } from 'react-router-dom'
import { Config } from '../const'
import { isIOS } from '../helper/userAgents'
import { InstallPrompt } from './InstallPrompt'

const Headline = styled(H3)`
  margin-top: 0;
`



const Start = () => {
  const navigate = useNavigate()
  return (
    <Page>
      <Content>
        <Headline>
          <Message id="WELCOME_HEADLINE" />
        </Headline>
       

        {isIOS ? (
          <Text>
            <Message id="WELCOME_MESSAGE_IOS" renderRawHtml />
          </Text>
        ) : (
          <Text>
            <Message id="WELCOME_MESSAGE" renderRawHtml />
          </Text>
        )}

        <InstallPrompt />

        {Config.REACT_APP_SHOW_NOT_REALEASED && (
          <>
            <Spacer size={4} />
            <H3>
              <Message id="WELCOME_MESSAGE_NOT_RELEASED" />
            </H3>
          </>
        )}
      </Content>
      <BottomDocked>
        <Row>
          <Button
            marginRight="3px"
            disabled={Config.REACT_APP_SHOW_NOT_REALEASED}
            onClick={() => {
              navigate('/timetable')
            }}
          >
            <Message id="WELCOME_BUTTON_START" />
          </Button>
          <Spacer size={2} />
        </Row>
      </BottomDocked>
    </Page>
  )
}

export default Start

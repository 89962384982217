import React, { useState, useCallback, useMemo } from 'react'
import useClipboard from '../hooks/useClipboard'
import {
  Button,
  H3,
  Text,
  Spacer,
  Page,
  Content,
  BottomDocked,
  Row,
  Divider,
} from '../components'
import styled from 'styled-components/macro'
import ReloadApp from './ReloadApp'
import useShare from '../hooks/useShare'
import T from '../components/T'
import { useT } from '../state/language'
import InputComp from '../components/Input/Input'
import { useNavigate } from 'react-router-dom'
import { Config } from '../const'

const Input = styled(InputComp)`
  &&& {
    background: black;
  }
`

const useMailText = favorites => {
  const t = useT()
  const favoritesStr = useMemo(() => JSON.stringify(favorites), [favorites])
  const favoritesForLink = favorites.join('-')

  const subject = t('FAVORITES_SHARE_TITLE')
  const link =
    Config.REACT_APP_URL +
    '/#/import/' +
    encodeURIComponent(favoritesForLink)
  const mailBody = useMemo(
    () => `${t('FAVORITES_SHARE_TEXT', link)}

    --- START ---
    ${favoritesStr}
    --- ENDE ---

    ${Config.REACT_APP_URL}
    `,
    [favoritesStr, link, t]
  )

  const mailTo = `mailto:?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(mailBody)}`

  return {
    favoritesStr,
    //  subject,
    mailBody,
    mailTo,
  }
}

const ImportExport = ({ favorites, importFavs }) => {
  const [importTxt, setImportTxt] = useState('')
  const [error, setError] = useState('')
  const t = useT()
  const navigate = useNavigate()

  const { favoritesStr, mailBody, mailTo } = useMailText(favorites)

  const [, setClipboard] = useClipboard(favoritesStr)

  const validateImport = useCallback(() => {
    // console.log('validateImport')
    try {
      let str = importTxt

      try {
        const regEx = new RegExp('(?<=--- START ---)(.*)(?=--- ENDE ---)', 's')
        const matched = importTxt.match(regEx)
        if (matched && matched[0]) {
          str = matched[0]
        }
      } catch (error) {}

      str = str.trim()
      // console.log('cleanString:', str)

      let newFavorites = JSON.parse(str)

      // console.log('Importiere: ', newFavorites)
      importFavs(newFavorites)
      setError('')
      alert(`${newFavorites.length} ${t('FAVORITES_IMPORT_SUCCESS')}`)
      setImportTxt('')
    } catch (error) {
      setError(t('FAVORITES_IMPORT_ERROR') + ' :: ' + error.toString())
      // eslint-disable-next-line no-console
      console.warn(error)
    }
  }, [importTxt, importFavs, t])

  const share = useShare(mailBody)

  return (
    <Page>
      <Content>
        <H3>
          <T id="FAVORITES_EXPORT_HEADLINE" />
        </H3>

        <Spacer size={2} />
        {navigator.share ? (
          <React.Fragment>
            <Text>
              <T id="FAVORITES_SHARE_DESC" />
            </Text>
            <Button onClick={share}>
              <T id="FAVORITES_SHARE_BTN" />
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Text>
              <T id="FAVORITES_EMAIL_DESC" />
            </Text>
            <Button as="a" href={mailTo} style={{ display: 'inline-block' }}>
              <T id="FAVORITES_EMAIL_BTN" />
            </Button>
          </React.Fragment>
        )}

        <Spacer size={2} />
        <Text>
          <T id="FAVORITES_CLIPBOARD_DESC" />
        </Text>
        <Button
          onClick={() => {
            setClipboard()
            alert(t('FAVORITES_COPY_CLIPBOARD_SUCCESS'))
          }}
        >
          <T id="FAVORITES_CLIPBOARD_BTN" />
        </Button>

        <Spacer size={4} />
        <Divider />
        <Spacer size={4} />

        <H3>
          <T id="FAVORITES_IMPORT_HEADLINE" />
        </H3>
        <Text>
          <T id="FAVORITES_IMPORT_DESC" />
        </Text>

        <Input
          placeholder=""
          autoFocus={false}
          value={importTxt}
          onChange={value => setImportTxt(value)}
        />
        <Spacer />
        {error && <Text>{error}</Text>}
        <Button flex="initial" onClick={validateImport}>
          <T id="FAVORITES_IMPORT_BTN" />
        </Button>

        <Spacer size={4} />
        <Divider />
        <Spacer size={4} />
        <ReloadApp />
      </Content>
      <BottomDocked>
        <Row>
          <Button onClick={() => navigate(-1)}>
            <T id="FAVORITES_CLOSE_BTN" />
          </Button>
        </Row>
      </BottomDocked>
    </Page>
  )
}

export default ImportExport

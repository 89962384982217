import { createAction } from 'redux-actions'
import { makeNamespace } from '../utils'

const reducerName = 'filter'
export const ns = makeNamespace(reducerName)

// types
export const OPEN_FILTER = ns.getType('OPEN_FILTER')
export const CLOSE_FILTER = ns.getType('CLOSE_FILTER')
export const CHANGE_FILTER = ns.getType('CHANGE_FILTER')
export const SELECT_FILTER = ns.getType('SELECT_FILTER')
export const RESET_FILTER = ns.getType('RESET_FILTER')
export const CHECK_SAVED_FILTER_VALUES = ns.getType('CHECK_SAVED_FILTER_VALUES')

// actions
export const openFilter = createAction(OPEN_FILTER)
export const closeFilter = createAction(CLOSE_FILTER)
export const selectFilter = createAction(SELECT_FILTER)
export const changeFilter = createAction(CHANGE_FILTER)
export const resetFilter = createAction(RESET_FILTER)
export const checkSavedFilterValues = createAction(CHECK_SAVED_FILTER_VALUES)

import * as R from 'ramda'
import { getFilters } from '../state/filter/selectors'
import { getFavorites, getTimetable, getArists } from '../state/timetable'
import { createSelector } from 'reselect'

export const getEntries = createSelector(
  getTimetable,
  getFavorites,
  getFilters,
  (state, onlyFavorites) => onlyFavorites,
  (timetable, favorites, filters, onlyFavorites) => {
    // let entries = timetable.slice(0, 100)
    let entries = timetable

    // console.log('getEntries() onlyFavorites : ', onlyFavorites)

    if (onlyFavorites) {
      entries = R.filter((entry) => R.contains(entry.showId, favorites))(
        entries
      )
      // filter by day
      entries = R.filter((entry) => entry.day === filters.days)(entries)
    } else if (filters.search) {
      // console.log('search: ' + filters.search)
      entries = R.filter(
        (entry) =>
          entry.artist.toLowerCase().includes(filters.search.toLowerCase()) ||
          entry.floor.toLowerCase().includes(filters.search.toLowerCase())
      )(entries)
    } else {
      // filter by day
      entries = R.filter((entry) => entry.day === filters.days)(entries)

      // filter by floor and type only for timetable
      if (!onlyFavorites) {
        // filter by floor
        if (filters.floors.length > 0) {
          entries = R.filter((entry) =>
            R.contains(entry.floor, filters.floors)
          )(entries)
        }

        // console.log('entries', entries)
        // filter by type
        if (filters.types.length > 0) {
          entries = R.filter((entry) => R.contains(entry.genre, filters.types))(
            entries
          )
        }
        // console.log('entries', entries)
      }
    }

    // add isFavorite prop
    entries = R.map((entry) => {
      if (R.contains(entry.showId, favorites)) {
        return {
          ...entry,
          isFavorite: true,
        }
      }
      return entry
    })(entries)

    return entries
  }
)

export const getEntriesForHours = createSelector(getEntries, (entries) => {
  return R.pipe(
    R.groupBy((entry) => entry.date + entry.from),
    R.values
  )(entries)
})

export const getEntriesForArtists = createSelector(
  getTimetable,
  getArists,
  getFavorites,
  getFilters,
  (state, onlyFavorites) => onlyFavorites,
  (timetable, artists, favorites, filters, onlyFavorites) => {
    // let entries = artists.slice(0, 100)
    // let entries = timetable
    let entries = artists

    // console.log({ timetable, artists })

    if (onlyFavorites) {
      entries = R.filter((entry) => R.contains(entry.showId, favorites))(
        entries
      )
    }

    // console.log(entries)

    if (filters.search && !onlyFavorites) {
      // console.log('search: ' + filters.search)
      entries = R.filter(
        (entry) =>
          entry.artist.toLowerCase().includes(filters.search.toLowerCase())
        // ||
        // entry.floor.toLowerCase().includes(filters.search.toLowerCase())
      )(entries)
    } else {
      // filter by day
      // entries = R.filter(entry => entry.day === filters.days)(entries)

      // filter by floor
      // if (filters.floors.length > 0) {
      //   entries = R.filter((entry) => R.contains(entry.floor, filters.floors))(
      //     entries
      //   )
      // }

      // console.log('entries', entries)
      // filter by type
      if (filters.types.length > 0) {
        entries = R.filter((entry) => R.contains(entry.genre, filters.types))(
          entries
        )
      }
      // console.log('entries 3', entries)
    }

    // add isFavorite prop
    entries = R.map((entry) => {
      if (R.contains(entry.showId, favorites)) {
        return {
          ...entry,
          isFavorite: true,
        }
      }
      return entry
    })(entries)

    entries = R.pipe(
      R.sortWith([
        R.ascend((entry) => {
          return entry.artist
        }),
        // R.ascend(show => {
        //   return consts.floors.indexOf(show.floor)
        // }),
      ]),
      R.groupBy((entry) => entry.artist.substring(0, 1).toUpperCase()),
      R.values
    )(entries)

    // console.log('entries 5', entries)

    return entries
  }
)

import { connect } from 'react-redux'

import { hasFilters } from '../state/filter/selectors'
import { resetFilter } from '../state/filter/actions'
import { BottomArea } from './BottomArea'

export const BottomAreaContainer = connect(
  (state, props) => {
    return {
      hasFilters: hasFilters(state),
    }
  },
  {
    resetFilter,
  }
)(BottomArea)

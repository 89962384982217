const floors = [
  'Palapa',
  'Roter Platz',
  'Luftschloss',
  'Palast der Republik',
  'Neuland',
  'Seebühne',
  'Triebwerke',
  'Datscha',
  'Tubebox',
  'Salon de Baile',
  'Weidendom',
  'Karl Kutter',
  'Casino',
  'Hpttrsn',
  'Cloud Cuckoo',
  'Velvet Soup',
  'Turmbühne',
  'Tanzwüste',
  'Sonnendeck',
  'Panne Eichel',
  'Trancefloor',
  'Querfeld',
  'Subardo',
  'Dubstation',
  'Extravaganza',
  'Rootsbase',
  'Content',
  'Harbour',
  'Harbour Garage',
  'Oase',
  'Workshop Hangar',
  'Outdoor',
  // 'Schubkarton',
  'Schuhkarton',
  'Räuberhöhle',
  'Tarmac',
  'Theater',
  'Toto Klub',
  'Tube Tresen',
  'La Ballena',
  'Kino',
  'Grand Palais',
  'Kinderspace',
].sort()

const floorDescriptions = {
  de: {
    Oase: 'Ein bunter Platz, organisiert von einem großen Netzwerk vieler unterschiedlicher Menschen. Ohne sie alle gäbe es dort kein warmes Wasser, keine kalte Mate, keine veganen Mettwurstigel, nix zu lesen, kein Klopapier, keine Workshop- und Infozelte, keine anregenden Diskussionen, keine offenen Ohren, keine wärmenden Schultern, keine Ruhezonen, kein nächtliches Feuer und keinen Kaffee am Morgen. Und weil es das alles gibt, könnt ihr abseits der anstrengenden Feierei entspannt in drei großen Bildungszelten mitmachen, vormachen, nachmachen, mitreden, nachlesen, ausreden, vorreiten, queer leben oder einfach da sein.',
    Harbor:
      'Through solidarity and cooperation, a patchwork of activists, collectives and varying NGOs have put together an area where you can start your journey to solidarity. The rising wave of fascism is on the horizon. But here you are safe to explore the world of discourse and debate. To engage with artists, intellectuals and ­activists from very different backgrounds – but united in the ­struggle against injustice, sexism and racism.<br /><br />❤️ and respect for participating groups and collectives and their work: Arab* Underground ⏺ No Nation Truck ⏺ Sea Watch ⏺ Tubman Network ⏺ Kurdisches Zentrum für Öffentlichkeitsarbeit ⏺ Electronic Frontier Foundation ⏺ Sudan Uprising ⏺ Mnemonic ⏺ No Border Poland ⏺ Passamontagna ⏺ Blindspots Frachcollective ⏺ Kein Abschiebe­­zen­t­rum am BER ⏺ Radical Aid Force ⏺ Palästina Spricht ⏺ Migrantifa ⏺ Women and Exile an Friends ⏺ Sea Punks ⏺ mafianeindanke ⏺ Good Night Imperial Pride ⏺ Mare Liberum ⏺ ROSA – Rolling Safespace ⏺ Alarmphone ⏺ Serena & Hannav',
    // 'Cloud Cuckoo': "Hier geht's richtig ab",
    Content: `Es wird nicht wieder alles wie früher sein und wir wollen sie auch nicht zurück, die alte Normalität! Mehr denn je träumen wir von einer Welt, die anders ist; einer Welt, in der unsere Solidarität uns trägt, wir das Leben und den Tod ehren und das Patriarchat endlich überwunden ist. Um das zu erreichen gibt es noch viel zu tun! 
     Die Menschen, die wir für euch zu uns ins conTent zu Talks, Diskussionen, Workshops und Panels eingeladen ­haben, um ihr Wissen und ihre Sicht auf die Welt zu teilen sind aus verschiedenen Kontexten der Welt und des Bewusstseins. Mit ­einigen haben wir schon seit über zwei Jahren Kontakt, manche sind erst im letzten Moment an Bord gehüpft. Alle sind ganz besonders und wir hoffen, dass sie uns einen guten Schub geben, um für eine gerechtere Welt zu kämpfen!
     Den Anfang macht eine Regenwaldaktivistin mit jahrzehntelanger ­Erfahrung – nur eine von drei 70-Jährigen im Programm. Und immer geht es auch um‘s Tanzen! Denn wie sagte einst eine ­Anarchistin: „Wenn ich nicht dazu ­tanzen kann, ist es nicht meine ­Revolution!“`,
    'Toto Klub Zirkus': `Nimm die U-Bahn und entdecke ­unseren riesigen Ghetto Blaster Night Club! ­Fahre Rollschuh auf der legendären Toto Piste, spiele Basketball mit King Kong und ­genieße Schorlen, Shakes, Pastis und Cocktails, oder verbringe eine Nacht mit Raffaello oder Mikeangelo im Love Room des Motototels. Spiel ­Minigolf mit God­zilla und genieße Weißwein und französische Bouletten während du dir im Friseursalon die Haare legen lässt und tanze mit nasty B-Boys und B-Girls zum Get Down am Vinyl Desk.`,
    'Cloud Cuckoo': `Wolkenkuckuck! Kings, Queens und Queers — hier könnt ihr sein, was ihr wollt! Komm in die ­wattige Wolkenwelt, in eine ­Utopie ohne Bodenhaftung. 
    Ein Ort voller überbordender ­Performances, Konzerte, DJs, schillernder Kostüme, viel ­Trashappeal und Open Minds. Willkommen zu dem Apocalyptic Drag Musical, der Oriental Drag Belly Show und dem legendären CherrYo!kie direkt vor den Toren des Cloud Cuckoo. 
    Wir feiern einen rauschenden ­Abschluss mit den Queens Against Borders und anschlie­ßender Disco Afterhour. 
    Keep it cute, Cuckoo’s!`,
    Extravaganza: `Mit klappernden Rädern ­eingefahren, um in ­bizar­re ­Welten zu entführen — ­schîefschråg ­kamen sie und sind kehrtverum ­wieder ­gegangen. Vorhang auf, ­Manege frei: Hereinspaziert und herausgetorkelt! Willkommen im Zirkus Extravaganza! Obskure Optik in den Realitäten der­ Unver­nunft, Wundertüten zum Anzünden, Soundkurven zum ­Reinlegen. Mit Dramaturgie ohne Konventionen werden bunte Klänge ­vielgefaltet. Die ­Maskerade des Alltags fällt. 
    Den Wahnsin zwischen Licht und ­Schatten ­verloren und wiedergefunden. Moyn ­verschmierte Schminke und kleckernder Secco. Ein Kaninchenbau als Portal zum Eintritt in den Zirkus im Zirkus im Zirkus.`,
  },
  en: {
    Oase: 'Ein bunter Platz, organisiert von einem großen Netzwerk vieler unterschiedlicher Menschen. Ohne sie alle gäbe es dort kein warmes Wasser, keine kalte Mate, keine veganen Mettwurstigel, nix zu lesen, kein Klopapier, keine Workshop- und Infozelte, keine anregenden Diskussionen, keine offenen Ohren, keine wärmenden Schultern, keine Ruhezonen, kein nächtliches Feuer und keinen Kaffee am Morgen. Und weil es das alles gibt, könnt ihr abseits der anstrengenden Feierei entspannt in drei großen Bildungszelten mitmachen, vormachen, nachmachen, mitreden, nachlesen, ausreden, vorreiten, queer leben oder einfach da sein.',
    Harbor:
      'Through solidarity and cooperation, a patchwork of activists, collectives and varying NGOs have put together an area where you can start your journey to solidarity. The rising wave of fascism is on the horizon. But here you are safe to explore the world of discourse and debate. To engage with artists, intellectuals and ­activists from very different backgrounds – but united in the ­struggle against injustice, sexism and racism.<br /><br />❤️ and respect for participating groups and collectives and their work: Arab* Underground ⏺ No Nation Truck ⏺ Sea Watch ⏺ Tubman Network ⏺ Kurdisches Zentrum für Öffentlichkeitsarbeit ⏺ Electronic Frontier Foundation ⏺ Sudan Uprising ⏺ Mnemonic ⏺ No Border Poland ⏺ Passamontagna ⏺ Blindspots Frachcollective ⏺ Kein Abschiebe­­zen­t­rum am BER ⏺ Radical Aid Force ⏺ Palästina Spricht ⏺ Migrantifa ⏺ Women and Exile an Friends ⏺ Sea Punks ⏺ mafianeindanke ⏺ Good Night Imperial Pride ⏺ Mare Liberum ⏺ ROSA – Rolling Safespace ⏺ Alarmphone ⏺ Serena & Hannav',
    Content: `Not everything will be like before – and we do not want it back- the old normality, the past! More than ever, we dream of a world that is different, a ­world in which our solidarity sustains us, we honor life and death and ­patriarchy is finally overcome. To achieve this, there is still much to do! 
   The people we invited for you to ­conTent for talks, ­discussions, workshops and panels to share their knowledge and perspective are from ­different corners of the world and consciousness. Some we invited over two years ago, some have just hopped on board at the last ­moment. All of them are very special and we hope they give us good impulses to keep fighting for a more just world!
   We open this year's edition with a 70-year old rainforest activist – one of three 70 ­year-old speakers. Dance we present in many different facets this year – as once an anarchist lady said: „If I cannot dance, its not my revolution!“`,
    'Toto Klub Zirkus': `Take the subway and discover with us our huge Ghetto Blaster Night Club and our traditional ­roller ­skating and karting area. Come to play with King Kong at the basketball court, drink spritz, milkshakes, pastis and cocktails and have a night with ­Raffaello or ­Mikeangelo in the Motototel Love Room. Play mini golf with ­Godzilla, eat French boulettes while drinking ­natural white wine, get a new hair cut in the barber shop and dance with the ­nasty B-boys and B-girls to the Get Down at the vinyl desk.`,
    'Cloud Cuckoo': `Cloud Cuckoo! Kings, Queens and Queers – here you can be whatever you want! Come to the wispy cloud world, to a utopia with no grounding. A place full of exuberant performances, concerts, DJs, dazzling costumes, lots of trashappeal and open minds. Welcome to the Apocalyptic Drag Musical, the Oriental Drag Belly Show and the legendary CherrYo!kie right outside the gates of Cloud Cuckoo. We‘ll be celebrating a glittering ­finale with Queens Against Borders followed by a disco afterhour. Keep it cute Cuckoo‘s.`,
    Extravaganza: `Curtain up, ring free: Staggered in and weaving out! Welcome to the Circus Extravaganza! With dramaturgy without conventions, colourful sounds are folded in many ways. The masquerade of everyday life falls. The madness between light and shadow lost and found again. A rabbit hole as a portal to enter the circus within the circus within the circus.`,
  },
}

const types = [
  'DJ',
  'Band',
  'Talk',
  'Live Act',
  'Film',
  'Performance',
  'Workshop',
  'Installation',
  'Theater',
  'Visuals & Optics',
  'Walk Act',
  'Neuer Zirkus',
  'Panel',
  'others',
]

const days = ['Mi', 'Do', 'Fr', 'Sa', 'So', 'Mo']

module.exports = {
  floors,
  days,
  types,
  floorDescriptions,
}

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('appState')
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

export const saveState = (state) => {
  try {
    // console.log('saveState', state)
    const serializedState = JSON.stringify(state)
    localStorage.setItem('appState', serializedState)
  } catch (err) {
    // Ignore write errors.
  }
}

export const loadProperty = (name) => {
  try {
    const serializedState = localStorage.getItem(name)
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

export const saveProperty = (name, value) => {
  try {
    // console.log('saveState', state)
    const serializedState = JSON.stringify(value)
    localStorage.setItem('name', serializedState)
  } catch (err) {
    // Ignore write errors.
  }
}
